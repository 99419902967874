define('ironsides-ui/components/ticket-lookup', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    classNames: ['ui search'],
    store: Em['default'].inject.service('store'),
    content: [],
    filteredResults: [],
    showResults: false,
    value: null,

    didInsertElement: function didInsertElement() {
      this.set('term', this.get('value.number'));
    },
    setTerm: Em['default'].observer('value.number', function () {
      if (this.get('value')) {
        this.set('term', this.get('value.number'));
      }
    }),

    filterAndShowResults: function filterAndShowResults() {
      var term = this.get('term').replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      if (term.length) {
        this.set('showResults', true);
        this.set('filteredResults', this.get('content').filter((function (ticket) {
          var pattern = new RegExp(".*" + term + ".*");
          return pattern.test(ticket.get('number'));
        }).bind(this)));
      }
      this.set('isLoading', false);
    },
    /*
    change: function(){
      var term = this.get('term');
      if(term) {
        var ticket = this.get('value');
        if(ticket) {
          if(ticket.get('number') == term) {
            return;
          } else {
            var newTicket = this.get('content').findBy('number',term);
            if(newTicket) {
              this.set('value', newTicket);
            } else {
              this.set('term', ticket.get('number'));
            }
          }
        }
      } else if(this.get('value')) {
        this.set('value', undefined);
      } 
      this.set('showResults',false);
    },*/

    actions: {
      doSearch: function doSearch(term) {
        if (term.length >= 3) {
          this.set('isLoading', true);
          if (!this.get('content').length) {
            this.get('store').query('ticket', { number: term }).then((function (results) {
              this.set('content', results);
              this.filterAndShowResults();
            }).bind(this));
          } else {
            this.filterAndShowResults();
          }
        } else {
          this.set('content', []);
        }
      },
      select: function select(ticket) {
        this.set('showResults', false);
        this.set('term', ticket ? ticket.get('number') : '');
        this.set('value', ticket);
      }
    }
  });

});