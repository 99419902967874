define('ironsides-ui/controllers/trip', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var TripController;

	TripController = Ember['default'].Controller.extend();

	exports['default'] = TripController;

});