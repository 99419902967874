define('ironsides-ui/controllers/inspection', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var InspectionController;

  InspectionController = Ember['default'].ObjectController.extend({
    arrangedItems: (function () {
      return this.get('inspected_items').sortBy('inspection_item.order');
    }).property('inspected_items')
  });

  exports['default'] = InspectionController;

});