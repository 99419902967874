define('ironsides-ui/routes/tickets/index', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Route.extend({
    queryParams: {
      date: { refreshModel: true }
    },
    model: function model(params) {
      var tDate = new Date();
      var pDate = new Date(params['date']);
      //Issues HERE with figuring out if date is empty for default
      if (pDate.toString() !== 'Invalid Date') {
        tDate = pDate;
      }

      tDate.setHours(0, 0, 0, 0);
      var start_date = tDate.toString();
      tDate.setHours(23, 23, 59, 999);
      var end_date = tDate.toString();
      return this.store.query('ticket', {
        start_date: start_date,
        end_date: end_date,
        unlocked_only: 1
      });
    }
  });

});