define('ironsides-ui/controllers/timesheet/activity/edit', ['exports', 'ironsides-ui/controllers/timesheet/activity/form', 'ember'], function (exports, ActivityForm, Ember) {

  'use strict';

  var EditActivityController = ActivityForm['default'].extend({
    actions: {
      saveActivity: function saveActivity() {
        var control, maPromises;
        control = this;
        maPromises = [];
        this.model.save().then((function () {
          maPromises = this.model.get('machineActivities').map(function (t) {
            return t.save();
          });
          Ember['default'].RSVP.allSettled(maPromises).then(function (states) {
            if (states.isEvery('state', 'fulfilled')) {
              control.transitionToRoute('timesheet.activities');
            }
          });
        }).bind(this), function () {});
      },
      deleteMachineActivity: function deleteMachineActivity(ma) {
        if (confirm('Delete?')) {
          ma.destroyRecord();
        }
      },
      addMachineActivity: function addMachineActivity() {
        this.store.createRecord('machine-activity', {
          activity: this.model
        });
      }
    }
  });

  exports['default'] = EditActivityController;

});