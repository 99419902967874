define('ironsides-ui/routes/bill', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Route.extend({
    model: function model(params) {
      return this.store.find('bill', params.bill_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('hauler').then(function (haulers) {
        controller.set('haulers', haulers.sortBy('name'));
      });
    },
    actions: {
      didSave: function didSave() {
        this.transitionTo('bills');
      }
    }
  });

});