define('ironsides-ui/serializers/inspection', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var InspectionSerializer;

  InspectionSerializer = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      inspected_items: {
        embedded: 'always'
      }
    }
  });

  exports['default'] = InspectionSerializer;

});