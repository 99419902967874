define('ironsides-ui/controllers/material/edit', ['exports', 'ember', 'ironsides-ui/controllers/material/form'], function (exports, Ember, MaterialFormController) {

	'use strict';

	var MaterialEdit;

	MaterialEdit = MaterialFormController['default'].extend();

	exports['default'] = MaterialEdit;

});