define('ironsides-ui/components/smart-option', ['exports', 'ember', 'ironsides-ui/components/smart-select'], function (exports, Ember, XSelectComponent) {

  'use strict';

  var isArray = Ember['default'].isArray;

  /**
   * Used to wrap a native `<option>` tag and associate an object with
   * it that can be bound. It can only be used in conjuction with a
   * containing `x-select` component
   *
   * @class Ember.XOptionComponent
   * @extends Ember.Component
   */
  exports['default'] = Ember['default'].Component.extend({
    tagName: 'option',
    attributeBindings: ['selected', 'name', 'disabled', 'value'],
    classNameBindings: [':x-option'],

    /**
     * The value associated with this option. When this option is
     * selected, the `x-select` will fire its action with this
     * value.
     *
     * @property value
     * @type Object
     * @default null
     */
    value: null,
    resolvedValue: Ember['default'].computed('value', function () {
      if (Ember['default'].PromiseProxyMixin.detect(this.get('value'))) {
        return this.get('value.content');
      } else {
        return this.get('value');
      }
    }),

    /**
     * Property bound to the `selected` attribute of the native
     * `<option>` element. It is aware of the containing `x-select`'s
     * value and will mark itself if it is the same.
     *
     * @private
     * @property selected
     * @type Boolean
     */
    selected: Ember['default'].computed('resolvedValue', 'select.resolvedValue', 'select.multiple', 'isDisabled', function () {
      var option_val = this.get('resolvedValue');
      var select_val = this.get('select.resolvedValue');

      if (this.get('select.multiple') && isArray(select_val)) {
        return select_val.contains(option_val);
      } else {
        return option_val === select_val;
      }
    }),

    /**
     * Register this x-option with the containing `x-select`
     *
     * @override
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var select = this.nearestOfType(XSelectComponent['default']);
      Ember['default'].assert("x-option component declared without enclosing x-select", !!select);
      this.set('select', select);
      select.registerOption(this);
    },

    /**
     * Unregister this x-option with its containing x-select.
     *
     * @override
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('select').unregisterOption(this);
    }
  });

});