define('ironsides-ui/routes/mills/new', ['exports', 'ember', 'ironsides-ui/routes/mill/form'], function (exports, Ember, MillFormRoute) {

  'use strict';

  var Route;

  Route = MillFormRoute['default'].extend({
    beforeModel: function beforeModel() {
      return this.store.filter('measurementType', function (measurementType) {
        return measurementType;
      }).then((function (_this) {
        return function (measurementTypes) {
          _this.set('measurement', measurementTypes.get('firstObject'));
          return console.log(_this.get('measurement'));
        };
      })(this));
    },
    model: function model() {
      return this.store.createRecord('mill', {
        measurement_type: this.get('measurement')
      });
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('mills');
      },
      saved: function saved() {
        return this.transitionTo('mills');
      }
    }
  });

  exports['default'] = Route;

});