define('ironsides-ui/routes/bills/new', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Em, RollbackMixin) {

  'use strict';

  exports['default'] = Em['default'].Route.extend(RollbackMixin['default'], {
    queryParams: {
      ticket_id: {
        replace: true
      },
      hauler_id: {
        replace: true
      }
    },
    model: function model(params) {
      var bill = this.store.createRecord('bill');

      if (params['hauler_id']) {
        this.store.find('hauler').then(function (hauler) {
          bill.set('hauler', hauler);
        });
      }
      if (params['ticket_id']) {
        this.store.find('ticket', params['ticket_id']).then((function (ticket) {
          this.store.createRecord('bill-item', {
            bill: bill,
            ticket: ticket
          });
        }).bind(this));
      }
      return bill;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('hauler').then(function (haulers) {
        controller.set('haulers', haulers.sortBy('name'));
      });
    },
    actions: {
      didSave: function didSave() {
        this.transitionTo('bills');
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('ticket_id', undefined);
        controller.set('hauler_id', undefined);
      }
    }
  });

});