define('ironsides-ui/tests/unit/controllers/timesheets/new-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('controller:timesheets/new', 'TimesheetsNewController', {});

  ember_qunit.test('it exists', function () {
    var controller;
    controller = this.subject();
    return ok(controller);
  });

});