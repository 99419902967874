define('ironsides-ui/controllers/job/form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ObjectController.extend({
    geo: Ember['default'].inject.service('geo'),
    needs: ["clients"],
    clients: Ember['default'].computed.alias('controllers.clients'),

    actions: {
      doGeocode: function doGeocode() {
        var address = this.get('model.full_location');
        if (address && address.length) {
          this.get('geo').geocode(address).then((function (data) {
            if (data) {
              this.set('latitude', data.lat);
              this.set('longitude', data.lng);
            }
          }).bind(this));
        }
      },
      save: function save() {
        return this.model.save().then((function (_this) {
          return function () {
            return _this.transitionToRoute('job', _this.model);
          };
        })(this), function () {});
      }
    }
  });

});