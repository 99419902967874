define('ironsides-ui/routes/clients/new', ['exports', 'ironsides-ui/routes/client/form'], function (exports, ClientForm) {

  'use strict';

  var NewClientRoute;

  NewClientRoute = ClientForm['default'].extend({
    model: function model() {
      return this.store.createRecord('client');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('clients');
      },
      saved: function saved() {
        return this.transitionTo('client', this.currentModel);
      }
    }
  });

  exports['default'] = NewClientRoute;

});