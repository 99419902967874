define('ironsides-ui/routes/machines', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachinesRoute;

  MachinesRoute = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    model: function model() {
      return this.store.find('machine');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('companies', this.get('haulers'));
      return controller.set('company', this.get('haulers').findBy('internal', true));
    }
  });

  exports['default'] = MachinesRoute;

});