define('ironsides-ui/routes/measurement-type/edit', ['exports', 'ember', 'ironsides-ui/routes/measurement-type/form'], function (exports, Ember, MeasurementTypeFormRoute) {

  'use strict';

  var Route;

  Route = MeasurementTypeFormRoute['default'].extend({
    actions: {
      cancel: function cancel() {
        return this.transitionTo('measurement-types.index');
      },
      saved: function saved() {
        return this.transitionTo('measurement-types.index');
      }
    }
  });

  exports['default'] = Route;

});