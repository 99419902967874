define('ironsides-ui/tests/controllers/reports/tickets.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/reports');
  QUnit.test('controllers/reports/tickets.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(false, 'controllers/reports/tickets.js should pass jshint.\ncontrollers/reports/tickets.js: line 3, col 8, \'moment\' is defined but never used.\n\n1 error'); 
  });

});