define('ironsides-ui/controllers/timesheet/activity/form', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var ActivityFormController;

	ActivityFormController = Ember['default'].Controller.extend();

	exports['default'] = ActivityFormController;

});