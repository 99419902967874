define('ironsides-ui/tests/components/cancel-button.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/cancel-button.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'components/cancel-button.js should pass jshint.'); 
  });

});