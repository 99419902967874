define('ironsides-ui/controllers/timesheets/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetsController;

  TimesheetsController = Ember['default'].ArrayController.extend({
    breadCrumb: 'Timesheets',
    sortProperties: ['date'],
    sortAscending: false,
    actions: {
      "delete": function _delete(item) {
        if (confirm('Delete this timesheet?')) {
          return item.destroyRecord();
        }
      }
    }
  });

  exports['default'] = TimesheetsController;

});