define('ironsides-ui/components/number-field', ['exports', 'ironsides-ui/components/form-field', 'moment'], function (exports, FormField, moment) {

  'use strict';

  var NumberField;

  NumberField = FormField['default'].extend({
    type: 'number',
    step: 1,
    min: 0
  });

  exports['default'] = NumberField;

});