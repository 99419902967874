define('ironsides-ui/routes/inspection', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var InspectionRoute;

  InspectionRoute = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('inspection', params.inspection_id);
    }
  });

  exports['default'] = InspectionRoute;

});