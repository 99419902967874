define('ironsides-ui/tests/unit/routes/timesheet/new-activity-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('route:timesheet/new-activity', 'TimesheetNewActivityRoute', {});

  ember_qunit.test('it exists', function () {
    var route;
    route = this.subject();
    return ok(route);
  });

});