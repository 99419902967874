define('ironsides-ui/controllers/modals/activity-inspection', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ActivityInspection;

  ActivityInspection = Ember['default'].ObjectController.extend({
    needs: ['timesheet-activity/edit', 'activities/new'],
    arrangedItems: (function () {
      return this.get('inspected_items').sortBy('inspection_item.order');
    }).property('inspected_items.[]'),
    allInspected: (function () {
      return !this.get('inspected_items').any(function (item) {
        return item.get('satisfactory') === null || item.get('satisfactory') === void 0;
      });
    }).property('inspected_items.@each.satisfactory'),
    disableSave: (function () {
      return !this.get('allInspected');
    }).property('allInspected'),
    actions: {
      cancel: function cancel() {
        var aNew, tsaEdit;
        this.get('inspected_items').invoke('rollback');
        this.model.rollback();
        tsaEdit = this.get('controllers.timesheet-activity/edit');
        if (tsaEdit.get('machineActivities')) {
          tsaEdit.get('machineActivities').filterBy('inspection', this.model).forEach(function (m) {
            return m.set('machine', null);
          });
        }
        aNew = this.get('controllers.activities/new');
        if (aNew.get('machineActivities')) {
          aNew.get('machineActivities').filterBy('inspection', this.model).forEach(function (m) {
            return m.set('machine', null);
          });
        }
        return this.send('closeModal');
      },
      save: function save() {
        return this.model.save().then((function (_this) {
          return function () {
            _this.model.get('inspected_items').filterBy('id', null).invoke('rollback');
            return _this.send('closeModal');
          };
        })(this), function () {});
      }
    }
  });

  exports['default'] = ActivityInspection;

});