define('ironsides-ui/components/inspection-form', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    model: null,
    arrangedItems: Em['default'].computed('model.inspected_items.[]', function () {
      return this.get('model.inspected_items').sortBy('inspection_item.order');
    }),
    allAreInspected: Em['default'].computed('model.inspected_items.@each.satisfactory', function () {
      return !this.get('model.inspected_items').any(function (item) {
        return item.get('satisfactory') == null || item.get('satisfactory') === undefined;
      });
    }),
    actions: {
      ok: function ok() {
        if (this.get('allAreInspected')) {
          this.get('model').save().then((function () {
            if (this.get('action')) {
              this.sendAction();
            }
          }).bind(this), function () {});
        }
      },
      cancel: function cancel() {
        if (this.get('cancel')) {
          this.sendAction('cancel');
        }
      }
    }
  });

});