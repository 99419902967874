define('ironsides-ui/controllers/users', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var UsersController;

  UsersController = Ember['default'].ArrayController.extend({
    session: Ember['default'].inject.service('session'),
    sortProperties: ['name'],
    sortAscending: true,
    actions: {
      loginAs: function loginAs(user) {
        var control;
        control = this;
        return this.session.changeCurrentUser(user).then(function () {
          return control.transitionToRoute('/');
        }, function (response) {});
      }
    }
  });

  exports['default'] = UsersController;

});