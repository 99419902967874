define('ironsides-ui/controllers/machine/inspections', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachineInspections;

  MachineInspections = Ember['default'].ArrayController.extend({
    sortProperties: ['time']
  });

  exports['default'] = MachineInspections;

});