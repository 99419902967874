define('ironsides-ui/tests/controllers/tickets/new.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/tickets');
  QUnit.test('controllers/tickets/new.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'controllers/tickets/new.js should pass jshint.'); 
  });

});