define('ironsides-ui/controllers/clients/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClientsIndexController;

  ClientsIndexController = Ember['default'].ArrayController.extend({
    breadCrumb: 'Clients',
    sortProperties: ['name']
  });

  exports['default'] = ClientsIndexController;

});