define('ironsides-ui/components/checkbox-field', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var CheckboxFieldComponent;

  CheckboxFieldComponent = Ember['default'].Component.extend({
    classNames: ['field ui checkbox toggle'],
    classNameBindings: ['hasError:error', 'hasHint'],
    type: 'checkbox',
    label: null,
    hint: null,
    placeholder: null,
    "for": '',
    elemId: (function () {
      var random;
      random = (0 | Math.random() * 9e6).toString(36);
      return this.get('for') + random;
    }).property('for'),
    model: (function () {
      return this.get('parentView.model');
    }).property('parentView.model'),
    init: function init() {
      this._super();
      Ember['default'].Binding.from('model.errors.' + this.get('for')).to('model_errors').connect(this);
      return Ember['default'].Binding.from('model.' + this.get('for')).to('value').connect(this);
    },
    hasError: (function () {
      return (this.get('model_errors') || []).length > 0;
    }).property('model_errors'),
    hasHint: (function () {
      return !!this.get('hint');
    }).property('hint'),
    hasLabel: (function () {
      return !!this.get('label');
    }).property('label')
  });

  exports['default'] = CheckboxFieldComponent;

});