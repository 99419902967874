define('ironsides-ui/models/payment', ['exports', 'ember-data', 'moment'], function (exports, DS, moment) {

  'use strict';

  var Payment;

  Payment = DS['default'].Model.extend({
    bill: DS['default'].belongsTo('bill', {
      async: true
    }),
    paid_date: DS['default'].attr('date'),
    check_number: DS['default'].attr('string'),
    amount: DS['default'].attr('number')
  });

  exports['default'] = Payment;

});