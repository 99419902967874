define('ironsides-ui/tests/authorizers/oauth2.jshint', function () {

  'use strict';

  QUnit.module('JSHint - authorizers');
  QUnit.test('authorizers/oauth2.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'authorizers/oauth2.js should pass jshint.'); 
  });

});