define('ironsides-ui/models/timesheet', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Timesheet;

  Timesheet = DS['default'].Model.extend({
    user: DS['default'].belongsTo('user', {
      async: true
    }),
    date: DS['default'].attr('utc'),
    created_at: DS['default'].attr('date'),
    locked_at: DS['default'].attr('date'),
    out_of_town: DS['default'].attr('boolean', {
      async: true
    }),
    activities: DS['default'].hasMany('activity', {
      async: true
    }),
    purchases: DS['default'].hasMany('purchase', {
      async: true
    }),
    inspections: DS['default'].hasMany('inspection', {
      async: true
    }),
    isLocked: (function () {
      return !!this.get('locked_at');
    }).property('locked_at'),
    hours: (function () {
      return this.get('activities').mapBy('hours').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('activities.@each.hours'),
    machineHours: (function () {
      return this.get('activities').mapBy('machineHours').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('activities.@each.machineHours'),
    miles: (function () {
      return this.get('activities').mapBy('miles').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('activities.@each.miles'),
    fuel: (function () {
      return this.get('activities').mapBy('fuel').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('activities.@each.fuel'),
    offroadMiles: (function () {
      return this.get('activities').mapBy('offroadMiles').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('activities.@each.offroadMiles'),
    onroadMiles: (function () {
      return this.get('activities').mapBy('onroadMiles').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('activities.@each.onroadMiles'),
    latestTime: (function () {
      return this.get('activities').sortBy('end_at').get('lastObject.end_at');
    }).property('activities.@each.end_at'),
    earliestTime: (function () {
      return this.get('activities').sortBy('start_at').get('firstObject.start_at');
    }).property('activities.@each.start_at'),
    lastJob: (function () {
      return this.get('activities').sortBy('end_at').get('lastObject.job');
    }).property('activities.@each.job'),
    purchaseTotal: (function () {
      return this.get('purchases').mapBy('amount').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('purchases.@each.amount')
  });

  exports['default'] = Timesheet;

});