define('ironsides-ui/controllers/machines/new', ['exports', 'ember', 'ironsides-ui/controllers/machine/form'], function (exports, Ember, MachineFormController) {

	'use strict';

	var MachinesNew;

	MachinesNew = MachineFormController['default'].extend();

	exports['default'] = MachinesNew;

});