define('ironsides-ui/models/measurement-type', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var MeasurementType;

  MeasurementType = DS['default'].Model.extend({
    name: DS['default'].attr('string')
  });

  exports['default'] = MeasurementType;

});