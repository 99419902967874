define('ironsides-ui/routes/purchase-category/edit', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var PurchaseCategoryEditRoute;

  PurchaseCategoryEditRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    actions: {
      cancel: function cancel() {
        return this.transitionTo('purchase_categories');
      }
    }
  });

  exports['default'] = PurchaseCategoryEditRoute;

});