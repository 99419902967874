define('ironsides-ui/controllers/machine/edit', ['exports', 'ember', 'ironsides-ui/controllers/machine/form'], function (exports, Ember, MachineFormController) {

	'use strict';

	var MachineEdit;

	MachineEdit = MachineFormController['default'].extend();

	exports['default'] = MachineEdit;

});