define('ironsides-ui/controllers/timesheet/inspections', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetInspections;

  TimesheetInspections = Ember['default'].ArrayController.extend({
    sortProperties: ['time']
  });

  exports['default'] = TimesheetInspections;

});