define('ironsides-ui/router', ['exports', 'ember', 'ironsides-ui/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router;

  Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('login');

    this.route('tickets', function () {
      this.route('new');
      this.route('admin');
    });
    this.route('ticket', { path: 'tickets/:ticket_id' }, function () {
      this.route('edit');
    });

    this.route('haulers', function () {
      this.route('new');
    });
    this.route('hauler', {
      path: 'haulers/:hauler_id'
    }, function () {
      this.route('edit');
    });
    this.route('mills', function () {
      this.route('new');
    });
    this.route('mill', {
      path: 'mills/:mill_id'
    }, function () {
      this.route('edit');
    });
    this.route('materials', function () {
      this.route('new');
    });
    this.route('material', {
      path: 'materials/:material_id'
    }, function () {
      this.route('edit');
    });
    this.route('measurement-types', function () {
      this.route('new');
    });
    this.route('measurement-type', {
      path: 'measurement-types/:measurement_type_id'
    }, function () {
      this.route('edit');
    });

    this.route('bills', function () {
      return this.route('new');
    });
    this.route('bill', { path: 'bills/:bill_id' });

    this.route('timesheets', function () {
      this.route('new');
    });
    this.route('timesheet', {
      path: 'timesheets/:timesheet_id'
    }, function () {
      this.route('purchases', {
        path: 'purchases'
      }, function () {
        this.route('new');
      });
      this.route('purchase', {
        path: 'purchases/:purchase_id'
      });
      this.route('inspections', {
        path: 'inspections'
      });
      this.route('activities', {
        path: 'activities'
      }, function () {
        this.route('new');
      });
      this.route('activity', {
        path: 'activities/:activity_id'
      }, function () {
        this.route('edit');
      });
    });
    this.route('admin');
    this.route('inspection', {
      path: 'inspections/:inspection_id'
    });
    this.route('users', function () {
      this.route('new');
    });
    this.route('user', {
      path: 'users/:user_id'
    }, function () {
      this.route('edit');
    });
    this.route('jobs', function () {
      this.route('new');
    });
    this.route('job', {
      path: 'jobs/:job_id'
    }, function () {
      this.route('edit');
    });
    this.route('machines', function () {
      this.route('new');
    });
    this.route('machine', {
      path: 'machines/:machine_id'
    }, function () {
      this.route('edit');
      this.route('inspections');
    });
    this.route('machine_types', function () {
      this.route('new');
    });
    this.route('machine_type', {
      path: 'machine_types/:machine_type_id'
    }, function () {
      this.route('edit');
      this.route('inspection_items');
    });
    this.route('purchase_categories', function () {
      this.route('new');
    });
    this.route('purchase_category', {
      path: 'purchase_categories/:category_id'
    }, function () {
      this.route('edit');
    });
    this.route('clients', function () {
      this.route('new');
    });
    this.route('client', {
      path: 'clients/:client_id'
    }, function () {
      this.route('edit');
    });
    this.route('vendors', function () {
      this.route('new');
    });
    this.route('vendor', {
      path: 'vendors/:vendor_id'
    });
    this.route('reports', function () {
      this.route('timesheets');
      this.route('resources');
      this.route('purchases');
      this.route('inspections');
      this.route('tickets');
      this.route('bills');
    });

    this.route('current_user');
  });

  exports['default'] = Router;

  // ---
  // generated by coffee-script 1.9.2

});