define('ironsides-ui/tests/controllers/bills/new.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/bills');
  QUnit.test('controllers/bills/new.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'controllers/bills/new.js should pass jshint.'); 
  });

});