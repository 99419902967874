define('ironsides-ui/routes/materials', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MaterialsRoute;

  MaterialsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('material');
    }
  });

  exports['default'] = MaterialsRoute;

});