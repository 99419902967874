define('ironsides-ui/controllers/reports/tickets', ['exports', 'ember', 'ironsides-ui/mixins/ajax-download', 'moment'], function (exports, Em, AjaxDownloadMixin, moment) {

  'use strict';

  exports['default'] = Em['default'].Controller.extend(AjaxDownloadMixin['default'], {
    queryParams: ["number", "invoice", "startDate", "endDate", "jobId", "millId", "materialId", "haulerId", "userId", "notPaid", "notInvoiced", "lockedStatus"],
    startDate: null,
    endDate: null,
    notPaid: false,
    notInvoiced: false,
    lockedStatus: 'unlocked',

    isLoading: Em['default'].computed.alias('model.isUpdating'),

    filteredContent: (function () {
      if (this.get('isLoading')) {
        return [];
      }
      return this.model.filter((function (item) {
        var matches = true;
        if (this.get('notPaid')) {
          if (item.get('load_paid')) {
            matches = false;
          }
        }
        if (matches && this.get('notInvoiced')) {
          if (item.get('truck_rate')) {
            matches = false;
          }
        }
        return matches;
      }).bind(this));
    }).property('isLoading', 'model.[]', 'notPaid', 'notInvoiced'),

    totalQuantity: Em['default'].computed('filteredContent.[]', function () {
      return this.get('filteredContent').reduce(function (prev, ticket) {
        return parseFloat(prev || 0) + parseFloat(ticket.get('quantity') || 0);
      }, 0);
    }),
    totalMillPaid: Em['default'].computed('filteredContent.[]', function () {
      return this.get('filteredContent').reduce(function (prev, ticket) {
        return parseFloat(prev || 0) + parseFloat(ticket.get('load_paid') || 0);
      }, 0);
    }),
    totalCost: Em['default'].computed('filteredContent.[]', function () {
      return this.get('filteredContent').reduce(function (prev, ticket) {
        return parseFloat(prev || 0) + parseFloat(ticket.get('truck_rate') || 0);
      }, 0);
    }),
    totalProfit: Em['default'].computed('filteredContent.[]', function () {
      return this.get('filteredContent').reduce(function (prev, ticket) {
        return parseFloat(prev || 0) + parseFloat(ticket.get('load_profit') || 0);
      }, 0);
    }),
    arrangedContent: Em['default'].computed('filteredContent.[]', function () {
      return this.get('filteredContent').sortBy('date').reverse();
    }),
    actions: {
      saveTicket: function saveTicket(ticket) {
        var control = this;
        Em['default'].run.debounce(ticket, function () {
          if (ticket.get('hasDirtyAttributes')) {
            ticket.save()['finally'](function () {
              control.get('flashMessages').success('Ticket Saved!', { timeout: 2000 });
            });
          }
        }, 700);
      },
      toggleLock: function toggleLock(ticket) {
        ticket.toggleProperty('isLocked');
        this.send('saveTicket', ticket);
      },
      toggleLockAll: function toggleLockAll() {
        this.toggleProperty('lockAll');
        var lockAll = this.get('lockAll');
        var tickets = this.get('filteredContent').filterBy('isLocked', !lockAll);
        tickets.forEach(function (ticket) {
          ticket.set('isLocked', !!lockAll);
          return ticket.save();
        });
      },
      exportCSV: function exportCSV() {
        this.downloadFileAjax('/api/search/tickets.csv', {
          data: {
            number: this.get('number'),
            start_date: this.get('startDate'),
            end_date: this.get('endDate'),
            job_id: this.get('jobId'),
            mill_id: this.get('millId'),
            material_id: this.get('materialId'),
            hauler_id: this.get('haulerId')
          },
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      }
    }
  });

});