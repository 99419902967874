define('ironsides-ui/routes/machine-types/new', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var MachineTypesNewRoute;

  MachineTypesNewRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    model: function model() {
      return this.store.createRecord('machine_type');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('machine_types');
      }
    }
  });

  exports['default'] = MachineTypesNewRoute;

});