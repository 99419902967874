define('ironsides-ui/routes/bills/index', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Route.extend({
    queryParams: {
      startDate: { refreshModel: true },
      endDate: { refreshModel: true },
      haulerId: { refreshModel: true },
      ticketNumber: { refreshModel: true },
      includePaid: { refreshModel: true },
      number: { refreshModel: true },
      checkNumber: { refreshModel: true }
    },
    model: function model(params) {
      return this.store.query('bill', {
        start_date: params['startDate'],
        end_date: params['endDate'],
        hauler_id: params['haulerId'],
        ticket_number: params['ticketNumber'],
        include_paid: params['includePaid'],
        number: params['number'],
        check_number: params['checkNumber']
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('hauler').then(function (haulers) {
        controller.set('haulers', haulers.sortBy('name'));
      });
    }
  });

});