define('ironsides-ui/components/modal-dialog', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    title: null,
    tagName: 'article',
    classNames: ['ui', 'modal'],
    okText: 'Ok',
    okIcon: 'icon check circle',
    isLoading: false,
    showCancelButton: false,
    refreshOnProperty: null,
    refreshTimeout: 800,

    hasFooter: (function () {
      return !!(this.get('action') || this.get('showCancelButton'));
    }).property('action', 'showCancelButton'),

    didInsertElement: function didInsertElement() {
      this.set('modal', this.$());

      if (this.get('refreshOnProperty')) {
        this.addObserver(this.get('refreshOnProperty'), this, (function () {
          Em['default'].run.later(this, this.refreshModal, this.get('refreshTimeout'));
        }).bind(this));
      }

      this.get('modal').modal({
        debug: true,
        selector: {
          close: '.close',
          approve: '',
          deny: ''
        },
        detachable: true,
        observeChanges: true,
        onHidden: (function () {
          this.set('isHidden', true);
          if (!this.get('isRemoved')) {
            if (this.get('cancel')) {
              this.sendAction('cancel');
            }
          }
        }).bind(this),
        onApprove: function onApprove() {
          return false;
        },
        onDeny: function onDeny() {
          return false;
        }
      }).modal('show');
    },

    willDestroyElement: function willDestroyElement() {
      this.set('isRemoved', true);
      if (!this.get('isHidden')) {
        this.get('modal').modal('hide');
      }
    },

    refreshModal: function refreshModal() {
      this.get('modal').modal('refresh');
    },

    actions: {
      ok: function ok() {
        this.sendAction();
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });

});