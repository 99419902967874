define('ironsides-ui/models/inspection-item', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var InspectionItem;

  InspectionItem = DS['default'].Model.extend({
    machineType: DS['default'].belongsTo('machineType', {
      async: true
    }),
    name: DS['default'].attr('string'),
    order: DS['default'].attr('number'),
    created_at: DS['default'].attr('date')
  });

  exports['default'] = InspectionItem;

});