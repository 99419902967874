define('ironsides-ui/controllers/measurement-type/edit', ['exports', 'ember', 'ironsides-ui/controllers/measurement-type/form'], function (exports, Ember, MeasurementTypeFormController) {

	'use strict';

	var MeasurementTypeEdit;

	MeasurementTypeEdit = MeasurementTypeFormController['default'].extend();

	exports['default'] = MeasurementTypeEdit;

});