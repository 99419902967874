define('ironsides-ui/routes/material/index', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Route.extend({
    model: function model() {
      return this.modelFor('material');
    }
  });

});