define('ironsides-ui/routes/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

	'use strict';

	var Route;

	Route = Ember['default'].Route.extend(AuthenticatedRouteMixin['default']);

	exports['default'] = Route;

});