define('ironsides-ui/controllers/haulers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var HaulersController;

  HaulersController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    sortAscending: true,
    arrangedContent: (function () {
      return this.get('model').sortBy('name');
    }).property('model.@each.name')
  });

  exports['default'] = HaulersController;

});