define('ironsides-ui/routes/machine-type', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Route;

  Route = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('machine_type', params.machine_type_id);
    }
  });

  exports['default'] = Route;

});