define('ironsides-ui/templates/job/form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 9,
                "column": 2
              }
            },
            "moduleName": "ironsides-ui/templates/job/form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","textarea-field",[],["for","admin_notes","label","Admin Notes"],["loc",[null,[8,4],[8,60]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "ironsides-ui/templates/job/form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("fieldset");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("legend");
          var el3 = dom.createTextNode("Location");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","ui button small");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","icon pin");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        Get Coordinates from Google\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","two fields");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [11]);
          var element1 = dom.childAt(element0, [11, 1]);
          var element2 = dom.childAt(element0, [13]);
          var morphs = new Array(16);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[5] = dom.createMorphAt(element0,3,3);
          morphs[6] = dom.createMorphAt(element0,5,5);
          morphs[7] = dom.createMorphAt(element0,7,7);
          morphs[8] = dom.createMorphAt(element0,9,9);
          morphs[9] = dom.createElementMorph(element1);
          morphs[10] = dom.createMorphAt(element2,1,1);
          morphs[11] = dom.createMorphAt(element2,3,3);
          morphs[12] = dom.createMorphAt(element0,15,15);
          morphs[13] = dom.createMorphAt(element0,17,17);
          morphs[14] = dom.createMorphAt(fragment,13,13,contextualElement);
          morphs[15] = dom.createMorphAt(fragment,15,15,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","select-field",[],["for","client","label","Client","content",["subexpr","@mut",[["get","clients",["loc",[null,[2,53],[2,60]]]]],[],[]]],["loc",[null,[2,2],[2,62]]]],
          ["inline","form-field",[],["for","name","label","Name *"],["loc",[null,[4,2],[4,42]]]],
          ["inline","textarea-field",[],["for","description","label","Description"],["loc",[null,[5,2],[5,58]]]],
          ["inline","form-field",[],["for","acreage","label","Acreage"],["loc",[null,[6,2],[6,46]]]],
          ["block","if",[["get","session.currentUser.admin",["loc",[null,[7,8],[7,33]]]]],[],0,null,["loc",[null,[7,2],[9,9]]]],
          ["inline","form-field",[],["for","address","label","Address"],["loc",[null,[13,4],[13,48]]]],
          ["inline","form-field",[],["for","city","label","City"],["loc",[null,[14,4],[14,42]]]],
          ["inline","form-field",[],["for","state","label","State"],["loc",[null,[15,4],[15,44]]]],
          ["inline","form-field",[],["for","postal_code","label","Postal Code"],["loc",[null,[16,4],[16,56]]]],
          ["element","action",["doGeocode"],[],["loc",[null,[18,38],[18,60]]]],
          ["inline","form-field",[],["for","latitude","debounce",true,"label","Latitude"],["loc",[null,[24,6],[24,66]]]],
          ["inline","form-field",[],["for","longitude","debounce",true,"label","Longitude"],["loc",[null,[25,6],[25,67]]]],
          ["inline","map-field",[],["latitude",["subexpr","@mut",[["get","model.latitude",["loc",[null,[27,25],[27,39]]]]],[],[]],"longitude",["subexpr","@mut",[["get","model.longitude",["loc",[null,[27,50],[27,65]]]]],[],[]]],["loc",[null,[27,4],[27,67]]]],
          ["inline","textarea-field",[],["for","directions","label","Driving Directions"],["loc",[null,[29,4],[29,66]]]],
          ["inline","checkbox-field",[],["for","active","model",["subexpr","@mut",[["get","model",["loc",[null,[32,38],[32,43]]]]],[],[]],"label","Active?"],["loc",[null,[32,2],[32,61]]]],
          ["inline","form-actions",[],["action","save","cancel","cancel"],["loc",[null,[33,2],[33,48]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "ironsides-ui/templates/job/form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","model-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[1,20],[1,25]]]]],[],[]],"action","save"],0,null,["loc",[null,[1,0],[34,15]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});