define('ironsides-ui/routes/measurement-types', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MeasurementTypesRoute;

  MeasurementTypesRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('measurement-type');
    }
  });

  exports['default'] = MeasurementTypesRoute;

});