define('ironsides-ui/models/machine', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Machine;

  Machine = DS['default'].Model.extend({
    company: DS['default'].belongsTo('hauler', {
      async: true
    }),
    name: DS['default'].attr('string'),
    odometer: DS['default'].attr('number'),
    hourmeter: DS['default'].attr('number'),
    machine_type: DS['default'].belongsTo('machineType', {
      async: true
    }),
    inspections: DS['default'].hasMany('inspection', {
      async: true
    })
  });

  exports['default'] = Machine;

});