define('ironsides-ui/routes/current-user', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var CurrentUserRoute;

  CurrentUserRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    session: Ember['default'].inject.service('session'),
    model: function model() {
      return this.get('session.currentUser');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('index');
      },
      didSave: function didSave() {}
    }
  });

  exports['default'] = CurrentUserRoute;

});