define('ironsides-ui/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, Ember, UnAuthenticatedRouteMixin) {

  'use strict';

  var LoginRoute;

  LoginRoute = Ember['default'].Route.extend(UnAuthenticatedRouteMixin['default'], {
    setupController: function setupController(controller, model) {
      return controller.set('failedLogin', false);
    }
  });

  exports['default'] = LoginRoute;

});