define('ironsides-ui/controllers/google-map/circles', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var computed = Ember['default'].computed;

  /**
   * @class GoogleMapCirclesController
   * @extends Ember.ArrayController
   */
  exports['default'] = Ember['default'].ArrayController.extend({
    itemController: computed.alias('parentController.circleController'),
    model: computed.alias('parentController.circles')
  });

});