define('ironsides-ui/controllers/clients/new', ['exports', 'ironsides-ui/controllers/client/form'], function (exports, ClientForm) {

	'use strict';

	var NewClientController;

	NewClientController = ClientForm['default'].extend();

	exports['default'] = NewClientController;

});