define('ironsides-ui/routes/mills', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MillsRoute;

  MillsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('mill');
    }
  });

  exports['default'] = MillsRoute;

});