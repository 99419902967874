define('ironsides-ui/models/purchase-category', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var PurchaseCategory;

  PurchaseCategory = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    purchases: DS['default'].hasMany('purchase'),
    require_state: DS['default'].attr('boolean'),
    require_quantity: DS['default'].attr('boolean'),
    quantity_unit: DS['default'].attr('string'),
    quantityUnitOrDefault: (function () {
      var pu;
      pu = this.get('quantity_unit');
      return pu || "Quantity";
    }).property('quantity_unit')
  });

  exports['default'] = PurchaseCategory;

});