define('ironsides-ui/controllers/jobs/new', ['exports', 'ironsides-ui/controllers/job/form'], function (exports, JobForm) {

  'use strict';

  var NewJob;

  NewJob = JobForm['default'].extend({
    breadCrumb: 'new'
  });

  exports['default'] = NewJob;

});