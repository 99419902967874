define('ironsides-ui/components/field-errors', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  var Component;

  Component = Ember.Component.extend({
    "for": (function () {
      return this.get('parentView.for');
    }).property('parentView.for'),
    model: (function () {
      return this.get('parentView.model');
    }).property('parentView.model'),
    init: function init() {
      this._super();
      return Ember.Binding.from('model.errors.' + this.get('for')).to('model_errors').connect(this);
    },
    errorMessage: (function () {
      return (this.get('model_errors') || []).mapBy('message')[0];
    }).property('model_errors.[]')
  });

  exports['default'] = Component;

});