define('ironsides-ui/tests/unit/views/application-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('view:application', 'ApplicationView');

  ember_qunit.test('it exists', function () {
    var view;
    view = this.subject();
    return ok(view);
  });

});