define('ironsides-ui/components/select-field', ['exports', 'ironsides-ui/components/form-field', 'ember'], function (exports, FormField, Ember) {

  'use strict';

  exports['default'] = FormField['default'].extend({
    content: [],
    multiple: false,
    prompt: " ",
    contentValuePath: false,
    contentLabelPath: "name",
    sortBy: Ember['default'].computed.alias('contentLabelPath'),
    selection: Ember['default'].computed.alias('value'),
    isLoading: (function () {
      return this.get('value.isPending') || this.get('content.isPending');
    }).property('value.isPending', 'content.isPending'),

    sortedContent: Ember['default'].computed('content.[]', 'isLoading', function () {
      return (this.get('content') || []).sortBy(this.get('sortBy'));
    }),

    isValueMissing: (function () {
      if (this.get('isLoading')) {
        return false;
      } else {
        var val = this.get('isValuePromise') ? this.get('value.content') : this.get('value');
        if (val) {
          if (this.get('contentValuePath')) {
            var contentValuePath = this.get('contentValuePath');
            return !(this.get('content') || []).any(function (item) {
              return item.get(contentValuePath) === val;
            });
          } else {
            return !(this.get('content') || []).contains(val);
          }
        } else {
          return false;
        }
      }
    }).property('isLoading', 'content.[]', 'isValuePromise'),

    isValuePromise: (function () {
      return Ember['default'].PromiseProxyMixin.detect(this.get('value'));
    }).property('value'),

    actions: {
      setValue: function setValue(val) {
        if (this.get('action')) {
          this.sendAction('action', val);
        } else {
          this.set('value', val);
        }
      }
    }
  });

});