define('ironsides-ui/routes/machine-type/inspection-items', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var InspectionItemsRoute;

  InspectionItemsRoute = Ember['default'].Route.extend({
    model: function model(params) {
      var mt;
      mt = this.modelFor('machine_type');
      return mt.get('inspectionItems');
    }
  });

  exports['default'] = InspectionItemsRoute;

});