define('ironsides-ui/routes/clients', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClientsRoute;

  ClientsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('client');
    }
  });

  exports['default'] = ClientsRoute;

});