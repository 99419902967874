define('ironsides-ui/routes/mill/form', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var Route;

  Route = Ember['default'].Route.extend(RollbackMixin['default'], {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('measurement_types', this.store.find('measurementType'));
    }
  });

  exports['default'] = Route;

});