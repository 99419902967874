define('ironsides-ui/models/job', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Job;

  Job = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    address: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    postal_code: DS['default'].attr('string'),
    latitude: DS['default'].attr('number'),
    longitude: DS['default'].attr('number'),
    created_at: DS['default'].attr('date'),
    updated_at: DS['default'].attr('date'),
    client: DS['default'].belongsTo('client', {
      async: true
    }),
    acreage: DS['default'].attr('string'),
    directions: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    admin_notes: DS['default'].attr('string'),
    active: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    full_location: (function () {
      return [[this.get('address'), this.get('city')].compact().join(' '), [this.get('state'), this.get('postal_code')].compact().join(' ')].compact().join(',');
    }).property('address', 'city', 'state', 'postal_code')
  });

  exports['default'] = Job;

});