define('ironsides-ui/routes/users/new', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var UsersNewRoute;

  UsersNewRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    model: function model() {
      return this.store.createRecord('user');
    },
    actions: {
      didSave: function didSave() {
        return this.transitionTo('users');
      },
      cancel: function cancel() {
        return this.transitionTo('users');
      }
    }
  });

  exports['default'] = UsersNewRoute;

});