define('ironsides-ui/controllers/measurement-type', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MeasurementType;

  MeasurementType = Ember['default'].ObjectController.extend({
    session: Ember['default'].inject.service('session')
  });

  exports['default'] = MeasurementType;

});