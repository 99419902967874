define('ironsides-ui/controllers/purchase-category-item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PurchaseCategoryItemController;

  PurchaseCategoryItemController = Ember['default'].ObjectController.extend({
    isEditing: false,
    editValue: null,
    copyNameToEditValue: (function () {
      return this.set('editValue', this.get('name'));
    }).observes('isEditing'),
    actions: {
      toggleEdit: function toggleEdit() {
        return this.set('isEditing', !this.get('isEditing'));
      },
      cancel: function cancel() {
        return this.set('isEditing', false);
      },
      saveCategory: function saveCategory() {
        this.set('name', this.get('editValue'));
        return this.model.save().then((function (_this) {
          return function () {
            return _this.set('isEditing', false);
          };
        })(this));
      }
    }
  });

  exports['default'] = PurchaseCategoryItemController;

});