define('ironsides-ui/controllers/login', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    session: Ember['default'].inject.service('session'),
    failedLogin: false,
    authenticator: 'authenticator:oauth2',
    isLoading: false,
    actions: {
      authenticate: function authenticate() {

        this.set('isLoading', true);

        var _getProperties = this.getProperties('identification', 'password');

        var identification = _getProperties.identification;
        var password = _getProperties.password;

        this.get('session').authenticate(this.get('authenticator'), identification, password).then((function () {
          this.set('failedLogin', false);
          //this.transitionTo('admin');
        }).bind(this), (function () {
          this.set('failedLogin', true);
        }).bind(this))['finally']((function () {
          this.set('isLoading', false);
        }).bind(this));
        this.set('password', null);
      }
    }
  });

});