define('ironsides-ui/components/timesheet-result', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    store: Em['default'].inject.service('store'),
    isLocked: false,

    timesheet: Em['default'].computed('id', function () {
      return this.store.find('timesheet', this.get('id'));
    }),

    toggleLocked: (function () {
      var locked = this.get('isLocked');
      this.get('timesheet').then(function (ts) {
        if (locked && !ts.get('locked_at')) {
          ts.set('locked_at', new Date());
          ts.save();
        } else if (!locked && ts.get('locked_at')) {
          ts.set('locked_at', null);
          ts.save();
        }
      });
    }).observes('isLocked'),

    setIsLocked: (function () {
      this.set('isLocked', !!this.get('locked_at'));
    }).on('init')
  });

});