define('ironsides-ui/tests/unit/models/purchase-category-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel('purchase-category', 'PurchaseCategory', {
    needs: []
  });

  ember_qunit.test('it exists', function () {
    var model;
    model = this.subject();
    return ok(!!model);
  });

});