define('ironsides-ui/routes/ticket/edit', ['exports', 'ironsides-ui/routes/ticket/form'], function (exports, Form) {

  'use strict';

  exports['default'] = Form['default'].extend({
    model: function model() {
      return this.modelFor('ticket');
    },
    actions: {
      didSave: function didSave() {
        this.transitionTo('ticket');
      }
    }
  });

});