define('ironsides-ui/controllers/job/edit', ['exports', 'ironsides-ui/controllers/job/form'], function (exports, JobForm) {

	'use strict';

	var EditJob;

	EditJob = JobForm['default'].extend();

	exports['default'] = EditJob;

});