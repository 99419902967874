define('ironsides-ui/controllers/purchase-categories', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PurchaseCategoriesController;

  PurchaseCategoriesController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    new_category_name: '',
    new_category_loading: false,
    new_catetory_error: false,
    actions: {
      addCategory: function addCategory() {
        var cat;
        if (this.get('new_category_name').length) {
          cat = this.store.createRecord('purchase-category', {
            name: this.get('new_category_name')
          });
          return cat.save().then((function (_this) {
            return function () {
              _this.set('new_category_name', '');
              return _this.set('new_category_error', false);
            };
          })(this), function () {
            return this.set('new_category_error', true);
          });
        }
      },
      deleteCategory: function deleteCategory(cat) {
        if (confirm('Delete this category?')) {
          return cat.destroyRecord();
        }
      }
    }
  });

  exports['default'] = PurchaseCategoriesController;

});