define('ironsides-ui/mixins/ajax-download', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Mixin;

  Mixin = Ember['default'].Mixin.create({
    downloadFileAjax: function downloadFileAjax(url, options) {
      return $.ajax(url, options).done(function (response, status, xhr) {
        var URL, a, blob, disposition, downloadUrl, filename, filenameRegex, matches, type;
        filename = '';
        disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          matches = filenameRegex.exec(disposition);
          if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        type = xhr.getResponseHeader('Content-Type');
        blob = new Blob([response], {
          type: type
        });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          URL = window.URL || window.webkitURL;
          downloadUrl = URL.createObjectURL(blob);
          if (filename) {
            a = document.createElement('a');
            if (typeof a.download === 'undefined') {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }
          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100);
        }
      });
    }
  });

  exports['default'] = Mixin;

});