define('ironsides-ui/controllers/mill', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Mill;

  Mill = Ember['default'].ObjectController.extend({
    session: Ember['default'].inject.service('session')
  });

  exports['default'] = Mill;

});