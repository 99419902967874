define('ironsides-ui/controllers/machine-activity/form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachineActivityForm;

  MachineActivityForm = Ember['default'].ObjectController.extend({
    needs: ['timesheet', 'machines'],
    timesheet: Ember['default'].computed.alias('controllers.timesheet'),
    machines: Ember['default'].computed.alias('controllers.machines.arrangedContent'),
    machineDidChange: (function () {
      return Ember['default'].run.once(this, 'promptInspection');
    }).observes('machine'),
    promptInspection: function promptInspection() {
      var control, machine;
      control = this;
      if (this.get('machine')) {
        machine = this.get('machine');
        if (machine.get('id')) {
          return machine.get('machine_type').then(function (mt) {
            if (mt.get('require_inspection')) {
              return control.get('timesheet.inspections').then(function (inspections) {
                var newInspection;
                if (!inspections.any(function (ins) {
                  return ins.get('machine.id') === machine.get('id');
                })) {
                  newInspection = control.store.createRecord('inspection', {
                    time: control.get('start_at') || new Date(),
                    machine: machine,
                    timesheet: control.get('timesheet.model'),
                    user: control.get('session.currentUser')
                  });
                  control.model.set('inspection', newInspection);
                  return control.send('showModal', 'modals/activity-inspection', newInspection);
                }
              });
            }
          });
        }
      }
    }
  });

  exports['default'] = MachineActivityForm;

});