define('ironsides-ui/tests/components/map-field.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/map-field.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'components/map-field.js should pass jshint.'); 
  });

});