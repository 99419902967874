define('ironsides-ui/controllers/bills/index', ['exports', 'ember', 'ironsides-ui/mixins/ajax-download'], function (exports, Em, AjaxDownloadMixin) {

  'use strict';

  exports['default'] = Em['default'].Controller.extend(AjaxDownloadMixin['default'], {
    queryParams: ['includePaid', 'startDate', 'endDate', 'haulerId', 'ticketNumber', 'number', 'checkNumber'],
    startDate: null,
    endDate: null,
    includePaid: false,
    grandTotal: Em['default'].computed('model.@each.total', function () {
      return this.get('model').reduce(function (val, item) {
        return val + (item.get('total') || 0);
      }, 0);
    }),
    totalBalance: Em['default'].computed('model.@each.balance', function () {
      return this.get('model').reduce(function (val, item) {
        return val + (item.get('balance') || 0);
      }, 0);
    }),

    arrangedContent: Em['default'].computed('model.@each.created_at', function () {
      return this.model.sortBy('created_at').reverse();
    }),
    actions: {
      downloadCSV: function downloadCSV() {
        this.downloadFileAjax('/api/search/bills.csv', {
          data: {
            number: this.get('number'),
            start_date: this.get('startDate'),
            end_date: this.get('endDate'),
            hauler_id: this.get('haulerId'),
            include_paid: this.get('includePaid')
          },
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      }
    }
  });

});