define('ironsides-ui/initializers/get-helper', ['exports', 'ember', 'ember-get-helper/utils/register-helper', 'ember-get-helper/helpers/get', 'ember-get-helper/helpers/get-glimmer'], function (exports, Em, register_helper, getHelper, getHelperGlimmer) {

  'use strict';

  exports.initialize = initialize;

  function initialize() /* container, application */{
    // Do not register helpers from Ember 1.13 onwards, starting from 1.13 they
    // will be auto-discovered.
    if (Em['default'].Helper) {
      return;
    }

    register_helper.registerHelper('get', getHelper['default']);
  }

  exports['default'] = {
    name: 'get-helper',
    initialize: initialize
  };

});