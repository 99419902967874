define('ironsides-ui/services/session', ['exports', 'ember', 'jquery', 'ember-simple-auth/services/session'], function (exports, Ember, $, SessionService) {

  'use strict';

  exports['default'] = SessionService['default'].extend({
    store: Ember['default'].inject.service('store'),

    currentUserId: Ember['default'].computed.alias('data.authenticated.resource_owner_id'),
    access_token: Ember['default'].computed.alias('data.authenticated.access_token'),

    changeCurrentUser: function changeCurrentUser(user) {
      var adapter = this.get('store').adapterFor('application');
      var uid = user.get('id');
      var url = adapter.buildURL("users/" + uid + "/login_as");
      var token = this.get('access_token');

      return $['default'].ajax({
        url: url,
        method: 'PUT',
        headers: { 'Authorization': 'Bearer ' + token }
      }).then((function () {
        this.set('currentUserId', uid);
      }).bind(this));
    },

    currentUser: (function () {
      if (this.get('currentUserId')) {
        return this.get('store').find('user', this.get('currentUserId'));
      } else {
        return null;
      }
    }).property('currentUserId', 'data')
  });

});