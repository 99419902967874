define('ironsides-ui/routes/timesheet/activities/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ActivitiesRoute;

  ActivitiesRoute = Ember['default'].Route.extend({
    model: function model() {
      var ts;
      ts = this.modelFor('timesheet');
      return ts.get('activities');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      return controller.set('timesheet', this.modelFor('timesheet'));
    }
  });

  exports['default'] = ActivitiesRoute;

});