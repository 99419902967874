define('ironsides-ui/controllers/timesheet/activities/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Activities;

  Activities = Ember['default'].Controller.extend({
    timesheet: null,
    breadCrumb: 'Activities',
    sortProperties: ['start_at'],
    sortAscending: false,
    arrangedContent: (function () {
      return Ember['default'].ArrayProxy.extend(Ember['default'].SortableMixin).create({
        content: this.get('model'),
        sortProperties: this.get('sortProperties'),
        sortAscending: this.get('sortAscending')
      });
    }).property('model.@each.start_at', 'sortProperties', 'sortAscending'),
    actions: {
      deleteActivity: function deleteActivity(activity) {
        if (confirm('Delete activity?')) {
          return activity.destroyRecord();
        }
      }
    }
  });

  exports['default'] = Activities;

});