define('ironsides-ui/components/datetime-field', ['exports', 'ironsides-ui/components/form-field', 'moment'], function (exports, FormField, moment) {

  'use strict';

  exports['default'] = FormField['default'].extend({
    type: 'datetime-local',
    setup: (function () {
      if (this.get('value')) {
        return this.updateDatetime();
      }
    }).on('init'),
    updateValue: (function () {
      var datetime = moment['default'](this.get("datetime"));
      if (datetime.isValid()) {
        return this.set("value", datetime.toDate());
      } else {
        return this.set("value", null);
      }
    }).observes("datetime"),
    updateDatetime: (function () {
      var datetime = moment['default'](this.get("value"));
      if (datetime.isValid()) {
        return this.set("datetime", datetime.format("YYYY-MM-DD[T]HH:mm"));
      } else {
        return this.set("datetime", null);
      }
    }).observes("value")
  });

});