define('ironsides-ui/routes/reports/resources', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ResourceReport;

  ResourceReport = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('company', this.get('haulers').findBy('internal', true));
      this.store.find('user').then(function (users) {
        return controller.set('users', users.sortBy('name'));
      });
      this.store.find('machineType').then(function (mt) {
        return controller.set('machineTypes', mt.sortBy('name'));
      });
      return this.store.find('job').then(function (jobs) {
        return controller.set('jobs', jobs.sortBy('name'));
      });
    }
  });

  exports['default'] = ResourceReport;

});