define('ironsides-ui/models/bill-item', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var BillItem;

  BillItem = DS['default'].Model.extend({
    bill: DS['default'].belongsTo('bill', {
      async: true
    }),
    ticket: DS['default'].belongsTo('ticket', {
      async: true
    }),
    description: DS['default'].attr('string'),
    total: DS['default'].attr('number')
  });

  exports['default'] = BillItem;

});