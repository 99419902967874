define('ironsides-ui/controllers/reports/timesheets', ['exports', 'ember', 'ironsides-ui/mixins/ajax-download'], function (exports, Ember, AjaxDownloadMixin) {

  'use strict';

  var reportsTimesheets;

  reportsTimesheets = Ember['default'].Controller.extend(AjaxDownloadMixin['default'], {
    queryParams: ['userId', 'jobId', 'startDate', 'endDate'],
    userId: null,
    jobId: null,
    startDate: null,
    endDate: null,
    exportDisabled: (function () {
      return !!this.get('model.length');
    }).property('model.[]'),
    hideLocked: true,
    total_hours: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('hours').reduce(function (a, b) {
          return a + b;
        });
      } else {
        return 0;
      }
    }).property('content.@each.hours'),
    total_miles: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('miles').reduce(function (a, b) {
          return a + b;
        });
      } else {
        return 0;
      }
    }).property('content.@each.miles'),
    getReport: (function () {
      var control;
      control = this;
      this.set('isLoading', true);
      return $.ajax('/api/search/timesheets.json', {
        data: {
          user_id: this.get('userId'),
          job_id: this.get('jobId'),
          start_date: this.get('startDate'),
          end_date: this.get('endDate'),
          out_of_town: this.get('out_of_town'),
          unlocked_only: !!this.get('hideLocked')
        },
        headers: {
          Authorization: 'Bearer ' + this.session.get('access_token')
        }
      }).then(function (data) {
        control.set('model', data.timesheets);
        return control.set('isLoading', false);
      });
    }).observes('userId', 'jobId', 'startDate', 'endDate', 'hideLocked'),
    actions: {
      lockChange: function lockChange(item) {
        if (!item['timesheet']) {
          item['timesheet'] = this.store.find('timesheet', item.id);
        }
        debugger;
        return item['timesheet'].then(function (timesheet) {
          if (item['locked_at']) {
            timesheet.set('locked_at', null);
            return timesheet.save().then(function () {
              return item['locked_at'] = null;
            });
          } else {
            timesheet.set('locked_at', new Date());
            return timesheet.save().then(function () {
              return item['locked_at'] = timesheet.get('locked_at');
            });
          }
        });
      },
      "export": function _export() {
        return this.downloadFileAjax('/api/search/timesheets.csv', {
          data: {
            user_id: this.get('userId'),
            job_id: this.get('jobId'),
            start_date: this.get('startDate'),
            end_date: this.get('endDate'),
            out_of_town: this.get('out_of_town')
          },
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      },
      print: function print() {
        var data;
        data = {
          user_id: this.get('userId'),
          job_id: this.get('jobId'),
          start_date: this.get('startDate'),
          end_date: this.get('endDate'),
          out_of_town: this.get('out_of_town')
        };
        return this.downloadFileAjax('/api/search/timesheets.pdf?' + $.param(data), {
          dataType: 'binary',
          processData: false,
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      }
    }
  });

  exports['default'] = reportsTimesheets;

});