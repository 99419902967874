define('ironsides-ui/routes/machine-types', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachineTypesRoute;

  MachineTypesRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('machineType');
    }
  });

  exports['default'] = MachineTypesRoute;

});