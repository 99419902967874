define('ironsides-ui/controllers/job/index', ['exports', 'ember', 'ironsides-ui/mixins/mobile-device-detection'], function (exports, Ember, MobiDetect) {

  'use strict';

  var JobIndex;

  JobIndex = Ember['default'].ObjectController.extend(MobiDetect['default'], {
    mapable: (function () {
      return !!(this.get('latitude') && this.get('longitude'));
    }).property('latitude', 'longitude'),
    mapMarkers: (function () {
      if (this.get('mapable')) {
        return Ember['default'].A([{
          lat: this.get('latitude'),
          lng: this.get('longitude')
        }]);
      } else {
        return null;
      }
    }).property('mapable', 'latitude', 'longitude'),
    mapCenter: (function () {
      return {
        latitude: this.get('latitude'),
        longitude: this.get('longitude')
      };
    }).property('mapable', 'latitude', 'longitude'),
    geoLink: (function () {
      return "geo://" + this.get('latitude') + "," + this.get('longitude');
    }).property('latitude', 'longitude')
  });

  exports['default'] = JobIndex;

});