define('ironsides-ui/controllers/machine-type', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var Controller;

	Controller = Ember['default'].ObjectController.extend();

	exports['default'] = Controller;

});