define('ironsides-ui/controllers/haulers/new', ['exports', 'ember', 'ironsides-ui/controllers/hauler/form'], function (exports, Ember, HaulerFormController) {

	'use strict';

	var HaulersNew;

	HaulersNew = HaulerFormController['default'].extend();

	exports['default'] = HaulersNew;

});