define('ironsides-ui/controllers/timesheet/activities/new', ['exports', 'ironsides-ui/controllers/timesheet/activity/form'], function (exports, ActivityForm) {

  'use strict';

  var NewActivityController;

  NewActivityController = ActivityForm['default'].extend({
    actions: {
      saveActivity: function saveActivity() {
        var control, maPromises;
        control = this;
        maPromises = [];
        return this.model.save().then(function () {
          maPromises = control.model.get('machineActivities').map(function (t) {
            return t.save();
          });
          return Ember.RSVP.all(maPromises).then(function () {
            return control.transitionToRoute('timesheet.activities');
          }, function () {});
        }, function () {});
      },
      deleteMachineActivity: function deleteMachineActivity(ma) {
        return ma.destroyRecord();
      },
      addMachineActivity: function addMachineActivity() {
        return this.store.createRecord('machine-activity', {
          activity: this.model
        });
      }
    }
  });

  exports['default'] = NewActivityController;

});