define('ironsides-ui/controllers/google-map/polygons', ['exports', 'ember', 'ironsides-ui/controllers/google-map/polylines'], function (exports, Ember, GoogleMapPolylinesController) {

  'use strict';

  var computed = Ember['default'].computed;

  /**
   * @class GoogleMapPolygonsController
   * @extends GoogleMapPolylinesController
   */
  exports['default'] = GoogleMapPolylinesController['default'].extend({
    itemController: computed.alias('parentController.polygonController'),
    model: computed.alias('parentController.polygons'),
    pathController: computed.alias('parentController.polygonPathController')
  });

});