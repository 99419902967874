define('ironsides-ui/routes/vendor', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var VendorRoute;

  VendorRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    model: function model(params) {
      return this.store.find('vendor', params.vendor_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('page_title', 'Edit Vendor');
    },
    actions: {
      didSave: function didSave() {
        return this.transitionTo('vendors');
      },
      cancel: function cancel() {
        return this.transitionTo('vendors');
      }
    }
  });

  exports['default'] = VendorRoute;

});