define('ironsides-ui/transforms/utc', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var utcTransform;

  utcTransform = DS['default'].Transform.extend({
    serialize: function serialize(value) {
      if (value) {
        return value.toJSON();
      } else {
        return null;
      }
    },
    deserialize: function deserialize(value) {
      if (value) {
        return moment(value, 'YYYY-MM-DD').toDate();
      } else {
        return null;
      }
    }
  });

  exports['default'] = utcTransform;

});