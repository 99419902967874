define('ironsides-ui/serializers/activity', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var ActivitySerializer;

	ActivitySerializer = DS['default'].ActiveModelSerializer.extend();

	exports['default'] = ActivitySerializer;

});