define('ironsides-ui/routes/material/edit', ['exports', 'ember', 'ironsides-ui/routes/material/form'], function (exports, Ember, MaterialFormRoute) {

  'use strict';

  var Route;

  Route = MaterialFormRoute['default'].extend({
    actions: {
      cancel: function cancel() {
        return this.transitionTo('materials.index');
      },
      saved: function saved() {
        return this.transitionTo('materials.index');
      }
    }
  });

  exports['default'] = Route;

});