define('ironsides-ui/controllers/clients', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClientsController;

  ClientsController = Ember['default'].ArrayController.extend({
    breadCrumb: 'Clients',
    sortProperties: ['name']
  });

  exports['default'] = ClientsController;

});