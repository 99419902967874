define('ironsides-ui/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ApplicationController;

  ApplicationController = Ember['default'].Controller.extend({
    session: Ember['default'].inject.service('session'),
    actions: {
      toggleMenu: function toggleMenu() {
        return this.set('menuOn', !this.get('menuOn'));
      },
      closeMenu: function closeMenu() {
        return this.set('menuOn', false);
      }
    }
  });

  exports['default'] = ApplicationController;

});