define('ironsides-ui/components/textarea-field', ['exports', 'ironsides-ui/components/form-field'], function (exports, FormField) {

	'use strict';

	var TextareaFieldComponent;

	TextareaFieldComponent = FormField['default'].extend();

	exports['default'] = TextareaFieldComponent;

});