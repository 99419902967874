define('ironsides-ui/routes/user', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var UserRoute;

  UserRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    model: function model(params) {
      return this.store.find(params.user_id);
    },
    actions: {
      didSave: function didSave() {
        return this.transitionTo('users');
      },
      cancel: function cancel() {
        return this.transitionTo('users');
      }
    }
  });

  exports['default'] = UserRoute;

});