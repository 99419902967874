define('ironsides-ui/routes/timesheet/activity', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var tsActivity;

  tsActivity = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('activity', params.activity_id);
    }
  });

  exports['default'] = tsActivity;

});