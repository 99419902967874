define('ironsides-ui/tests/components/ticket-lookup.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components');
  QUnit.test('components/ticket-lookup.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'components/ticket-lookup.js should pass jshint.'); 
  });

});