define('ironsides-ui/controllers/measurement-types/new', ['exports', 'ember', 'ironsides-ui/controllers/material/form'], function (exports, Ember, MeasurementTypeFormController) {

	'use strict';

	var MeasurementTypesNew;

	MeasurementTypesNew = MeasurementTypeFormController['default'].extend();

	exports['default'] = MeasurementTypesNew;

});