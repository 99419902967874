define('ironsides-ui/components/date-field', ['exports', 'ironsides-ui/components/form-field', 'moment'], function (exports, FormField, moment) {

  'use strict';

  exports['default'] = FormField['default'].extend({
    type: 'date',
    setup: (function () {
      if (this.get('value')) {
        return this.updateDate();
      }
    }).on('init'),
    updateValue: (function () {
      var date;
      date = moment['default'](this.get("date"));
      if (date.isValid()) {
        return this.set("value", date.toDate());
      } else {
        return this.set('value', null);
      }
    }).observes("date"),
    updateDate: (function () {
      var date;
      date = moment['default'](this.get("value"));
      if (date.isValid()) {
        return this.set("date", date.format("YYYY-MM-DD"));
      } else {
        return this.set("date", null);
      }
    }).observes("value")
  });

});