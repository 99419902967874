define('ironsides-ui/controllers/client/edit', ['exports', 'ironsides-ui/controllers/client/form'], function (exports, ClientForm) {

	'use strict';

	var EditClientController;

	EditClientController = ClientForm['default'].extend();

	exports['default'] = EditClientController;

});