define('ironsides-ui/controllers/haulers/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var HaulersIndexController;

  HaulersIndexController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    sortAscending: true
  });

  exports['default'] = HaulersIndexController;

});