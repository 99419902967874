define('ironsides-ui/routes/timesheet/purchases', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PurchasesRoute;

  PurchasesRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('timesheet').get('purchases');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('timesheet', this.controllerFor('timesheet'));
    }
  });

  exports['default'] = PurchasesRoute;

});