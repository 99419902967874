define('ironsides-ui/tests/routes/bills/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/bills');
  QUnit.test('routes/bills/index.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'routes/bills/index.js should pass jshint.'); 
  });

});