define('ironsides-ui/controllers/purchase-categories/new', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Controller;

  Controller = Ember['default'].ObjectController.extend({
    actions: {
      save: function save() {
        return this.model.save().then((function (_this) {
          return function () {
            return _this.transitionToRoute('purchase_categories');
          };
        })(this), function () {});
      }
    }
  });

  exports['default'] = Controller;

});