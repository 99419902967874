define('ironsides-ui/models/inspection', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Inspection;

  Inspection = DS['default'].Model.extend({
    time: DS['default'].attr('date'),
    user: DS['default'].belongsTo('user', {
      async: true
    }),
    timesheet: DS['default'].belongsTo('timesheet', {
      async: true
    }),
    machine: DS['default'].belongsTo('machine', {
      async: true
    }),
    inspected_items: DS['default'].hasMany('inspectedItem', {
      embedded: 'always'
    }),
    buildItems: (function () {
      var inspectionItemIds, me;
      if (this.get('machine.id')) {
        me = this;
        inspectionItemIds = this.get('inspected_items').mapBy('inspection_item.id');
        return this.get('machine').then(function (m) {
          return m.get('machine_type').then(function (mt) {
            return mt.get('inspectionItems').then(function (inspection_items) {
              return inspection_items.forEach(function (item) {
                if (!inspectionItemIds.contains(item.get('id'))) {
                  return me.store.createRecord('inspectedItem', {
                    inspection: me,
                    inspection_item: item
                  });
                }
              });
            });
          });
        });
      }
    }).observes('machine.id'),
    issueCount: (function () {
      return this.get('inspected_items').filterBy('satisfactory', false).length;
    }).property('inspected_items.@each.satisfactory'),
    hasIssues: (function () {
      return this.get('issueCount') > 0;
    }).property('issueCount')
  });

  exports['default'] = Inspection;

});