define('ironsides-ui/routes/machine/inspections', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachineInspections;

  MachineInspections = Ember['default'].Route.extend({
    model: function model() {
      var machine;
      machine = this.modelFor('machine');
      return this.store.filter('inspection', {
        machine_id: machine.get('id')
      }, function (i) {
        return i.get('machine.id') === machine.get('id');
      });
    }
  });

  exports['default'] = MachineInspections;

});