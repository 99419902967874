define('ironsides-ui/components/focused-input', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FocusedInput;

  FocusedInput = Ember['default'].TextField.extend({
    becomeFocused: (function () {
      return this.$().focus();
    }).on('didInsertElement')
  });

  exports['default'] = FocusedInput;

});