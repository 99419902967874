define('ironsides-ui/controllers/mills', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MillsController;

  MillsController = Ember['default'].Controller.extend({
    sortProperties: ['name'],
    sortAscending: true,
    arrangedContent: (function () {
      return this.get('model').sortBy('name');
    }).property('model.@each.name')
  });

  exports['default'] = MillsController;

});