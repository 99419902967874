define('ironsides-ui/routes/reports/timesheets', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetsReport;

  TimesheetsReport = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.find('user').then(function (users) {
        return controller.set('users', users.sortBy('name'));
      });
      return this.store.find('job').then(function (jobs) {
        return controller.set('jobs', jobs.sortBy('name'));
      });
    }
  });

  exports['default'] = TimesheetsReport;

});