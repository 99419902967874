define('ironsides-ui/templates/timesheet/activity/_form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 6
              },
              "end": {
                "line": 16,
                "column": 6
              }
            },
            "moduleName": "ironsides-ui/templates/timesheet/activity/_form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","machine-activity-fields",[],["model",["subexpr","@mut",[["get","ma",["loc",[null,[15,40],[15,42]]]]],[],[]],"machines",["subexpr","@mut",[["get","machines",["loc",[null,[15,52],[15,60]]]]],[],[]],"removeMachineActivity",["subexpr","action",["deleteMachineActivity"],[],["loc",[null,[15,83],[15,115]]]]],["loc",[null,[15,8],[15,117]]]]
          ],
          locals: ["ma"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2
            },
            "end": {
              "line": 25,
              "column": 2
            }
          },
          "moduleName": "ironsides-ui/templates/timesheet/activity/_form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1,"class","machine-activities");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h4");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","fa fa-truck");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        Machines/Equipment\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("menu");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3,"class","ui button");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","icon circle plus");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Add Machine or Trip");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("fieldset");
          dom.setAttribute(el1,"class","actions");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [9]);
          var element1 = dom.childAt(element0, [5, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[4] = dom.createMorphAt(element0,3,3);
          morphs[5] = dom.createElementMorph(element1);
          morphs[6] = dom.createMorphAt(dom.childAt(fragment, [11]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-field",[],["for","start_at","label","Start Time","step","1800","dateBase",["subexpr","@mut",[["get","model.timesheet.date",["loc",[null,[2,72],[2,92]]]]],[],[]]],["loc",[null,[2,4],[2,94]]]],
          ["inline","time-field",[],["for","end_at","label","End Time","step","1800","dateBase",["subexpr","@mut",[["get","model.timesheet.date",["loc",[null,[3,68],[3,88]]]]],[],[]]],["loc",[null,[3,4],[3,90]]]],
          ["inline","select-field",[],["for","job","label","Job","content",["subexpr","@mut",[["get","jobs",["loc",[null,[5,49],[5,53]]]]],[],[]],"optionLabelPath","content.name"],["loc",[null,[5,4],[5,86]]]],
          ["inline","form-field",[],["for","description","label","Description"],["loc",[null,[7,4],[7,56]]]],
          ["block","each",[["get","model.machineActivities",["loc",[null,[14,14],[14,37]]]]],[],0,null,["loc",[null,[14,6],[16,15]]]],
          ["element","action",["addMachineActivity"],[],["loc",[null,[18,34],[18,65]]]],
          ["inline","form-actions",[],["cancel","cancel"],["loc",[null,[23,6],[23,38]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "ironsides-ui/templates/timesheet/activity/_form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","model-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[1,22],[1,27]]]]],[],[]],"action","saveActivity"],0,null,["loc",[null,[1,2],[25,17]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});