define('ironsides-ui/components/form-actions', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    model: Ember['default'].computed.alias('parentView.model'),
    actions: {
      submit: function submit() {
        this.sendAction('submit');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });

});