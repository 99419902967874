define('ironsides-ui/controllers/materials/new', ['exports', 'ember', 'ironsides-ui/controllers/material/form'], function (exports, Ember, MaterialFormController) {

	'use strict';

	var MaterialsNew;

	MaterialsNew = MaterialFormController['default'].extend();

	exports['default'] = MaterialsNew;

});