define('ironsides-ui/models/vendor', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Vendor;

  Vendor = DS['default'].Model.extend({
    name: DS['default'].attr('string')
  });

  exports['default'] = Vendor;

});