define('ironsides-ui/models/bill', ['exports', 'ember-data', 'ember'], function (exports, DS, Em) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    hauler: DS['default'].belongsTo('hauler', {
      async: true
    }),
    payments: DS['default'].hasMany('payment'),
    billItems: DS['default'].hasMany('bill-item'),
    number: DS['default'].attr('string'),
    total: DS['default'].attr('number', { 'default': 0 }),
    created_at: DS['default'].attr('date'),

    ticketNumbers: Em['default'].computed('billItems.@each.ticket.number', function () {
      return this.get('billItems').mapBy('ticket.number').join(',');
    }),

    balance: Em['default'].computed('payments.@each.amount', 'total', function () {
      var paid = this.get('payments').reduce(function (prev, payment) {
        return prev + (parseFloat(payment.get('amount')) || 0);
      }, 0);

      return (this.get('total') || 0) - paid;
    }),

    doUpdateTotal: Em['default'].observer('billItems.@each.total', function () {
      this.set('total', this.get('billItems').reduce(function (prev, item) {
        return prev + (parseFloat(item.get('total')) || 0);
      }, 0));
    })

  });

});