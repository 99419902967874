define('ironsides-ui/models/mill', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Mill;

  Mill = DS['default'].Model.extend({
    measurement_type: DS['default'].belongsTo('measurementType', {
      async: true
    }),
    tickets: DS['default'].hasMany('ticket', {
      async: true
    }),
    name: DS['default'].attr('string'),
    address: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    state: DS['default'].attr('string'),
    postal_code: DS['default'].attr('string'),
    phone: DS['default'].attr('string')
  });

  exports['default'] = Mill;

});