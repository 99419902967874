define('ironsides-ui/controllers/timesheet/purchases/new', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var NewTimesheetPurchase;

  NewTimesheetPurchase = Ember['default'].ObjectController.extend({
    needs: ['vendors', 'machines', 'purchase_categories'],
    vendors: Ember['default'].computed.alias('controllers.vendors.arrangedContent'),
    machines: Ember['default'].computed.alias('controllers.machines.arrangedContent'),
    purchase_categories: Ember['default'].computed.alias('controllers.purchase_categories.arrangedContent'),
    actions: {
      save: function save() {
        return this.model.save().then((function (_this) {
          return function () {
            return _this.send('didSave', _this.model);
          };
        })(this), function () {});
      }
    }
  });

  exports['default'] = NewTimesheetPurchase;

});