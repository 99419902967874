define('ironsides-ui/routes/machines/new', ['exports', 'ember', 'ironsides-ui/routes/machine/form'], function (exports, Ember, MachineFormRoute) {

  'use strict';

  var Route;

  Route = MachineFormRoute['default'].extend({
    beforeModel: function beforeModel() {
      return this.get('store').filter('hauler', {
        internal: true
      }, function (hauler) {
        return hauler.get('internal');
      }).then((function (_this) {
        return function (haulers) {
          return _this.set('hauler', haulers.get('firstObject'));
        };
      })(this));
    },
    model: function model() {
      return this.store.createRecord('machine', {
        company: this.get('hauler')
      });
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('machines');
      },
      saved: function saved() {
        return this.transitionTo('machines');
      }
    }
  });

  exports['default'] = Route;

});