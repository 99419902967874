define('ironsides-ui/tests/routes/reports/inspections.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/reports');
  QUnit.test('routes/reports/inspections.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'routes/reports/inspections.js should pass jshint.'); 
  });

});