define('ironsides-ui/controllers/reports/inspections', ['exports', 'ember', 'ironsides-ui/mixins/ajax-download', 'jquery'], function (exports, Em, AjaxDownloadMixin, $) {

  'use strict';

  exports['default'] = Em['default'].Controller.extend(AjaxDownloadMixin['default'], {
    queryParams: ['userId', 'machineId', 'machineTypeId', 'startDate', 'endDate'],
    userId: null,
    machineId: null,
    machineTypeId: null,
    startDate: null,
    endDate: null,

    arrangedContent: (function () {
      if (this.get('model')) {
        return this.get('model').sortBy('name');
      } else {
        return [];
      }
    }).property('model.@each.time'),

    exportDisabled: (function () {
      return !!this.get('model.length');
    }).property('model.length'),

    getReport: (function () {
      if (this.get('filtersExist')) {
        this.set('isLoading', true);
        $['default'].ajax('/api/search/inspections.json', {
          data: this.get('reportFilters'),
          headers: {
            Authorization: "Bearer " + this.session.get('access_token')
          }
        }).then((function (data) {
          this.set('model', data.inspections);
          this.set('isLoading', false);
        }).bind(this), (function () {
          this.set('isLoading', false);
        }).bind(this));
      }
    }).observes('userId', 'machineId', 'startDate', 'endDate', 'machineTypeId'),

    reportFilters: (function () {
      return {
        user_id: this.get('userId'),
        machine_id: this.get('machineId'),
        machine_type_id: this.get('machineTypeId'),
        start_date: this.get('startDate'),
        end_date: this.get('endDate')
      };
    }).property('userId', 'machineId', 'startDate', 'endDate', 'machineTypeId'),

    filtersExist: (function () {
      for (var key in this.get('reportFilters')) {
        if (this.get('reportFilters')[key]) {
          return true;
        }
      }
      return false;
    }).property('reportFilters'),

    actions: {
      print: function print() {
        this.set('isDownloading', true);
        // for binary data type, send params in url
        this.downloadFileAjax('/api/search/inspections.pdf?' + $['default'].param(this.get('reportFilters')), {
          dataType: 'binary',
          processData: false,
          headers: {
            Authorization: "Bearer " + this.session.get('access_token')
          }
        }).done((function () {
          this.set('isDownloading', false);
        }).bind(this));
      }
    }
  });

});