define('ironsides-ui/views/no-bubble-click', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var NoBubbleClickView;

  NoBubbleClickView = Ember['default'].View.extend({
    click: function click(e) {
      return e.stopPropagation();
    }
  });

  exports['default'] = NoBubbleClickView;

});