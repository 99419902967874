define('ironsides-ui/controllers/google-map/polylines', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var computed = Ember['default'].computed;

  /**
   * @class GoogleMapPolylinesController
   * @extends Ember.ArrayController
   */
  exports['default'] = Ember['default'].ArrayController.extend({
    itemController: computed.alias('parentController.polylineController'),
    model: computed.alias('parentController.polylines'),
    pathController: computed.alias('parentController.polylinePathController')
  });

});