define('ironsides-ui/mixins/routes/rollback', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Mixin;

  Mixin = Ember['default'].Mixin.create({
    rollback: function rollback() {
      var model;
      model = this.get('currentModel');
      if (model.get('hasDirtyAttributes')) {
        return model.rollback();
      }
    },
    deactivate: function deactivate() {
      return this.rollback();
    }
  });

  exports['default'] = Mixin;

});