define('ironsides-ui/controllers/client', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClientController;

  ClientController = Ember['default'].ObjectController.extend({
    breadCrumb: Ember['default'].computed.alias('name')
  });

  exports['default'] = ClientController;

});