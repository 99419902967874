define('ironsides-ui/controllers/material', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Material;

  Material = Ember['default'].ObjectController.extend({
    session: Ember['default'].inject.service('session')
  });

  exports['default'] = Material;

});