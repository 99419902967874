define('ironsides-ui/controllers/client/form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClientFormController;

  ClientFormController = Ember['default'].ObjectController.extend({
    actions: {
      save: function save() {
        return this.model.save().then((function (_this) {
          return function () {
            return _this.send('saved');
          };
        })(this), function () {});
      }
    }
  });

  exports['default'] = ClientFormController;

});