define('ironsides-ui/routes/reports/inspections', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (haulers) {
        this.set('haulers', haulers);
      }).bind(this));
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.find('user').then(function (users) {
        controller.set('users', users.sortBy('name'));
      });

      var company = this.get('haulers').findBy('internal', true);
      this.store.find('machine').then(function (machines) {
        controller.set('machines', machines.filterBy('company.id', company.get('id')).sortBy('name'));
      });

      this.store.find('machine-type').then(function (mt) {
        controller.set('machineTypes', mt.sortBy('name'));
      });
    }
  });

});