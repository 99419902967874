define('ironsides-ui/routes/machine', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Route;

  Route = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('machine', params.machine_id);
    }
  });

  exports['default'] = Route;

});