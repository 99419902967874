define('ironsides-ui/services/geo', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Service.extend({
    geocode: function geocode(address) {
      return Em['default'].$.ajax("https://maps.googleapis.com/maps/api/geocode/json", {
        type: 'GET',
        dataType: 'json',
        data: {
          address: address
        }
      }).then(function (data) {
        if (data['results'].length) {
          if (data['results'][0].geometry) {
            return {
              lat: data['results'][0]['geometry']['location']['lat'],
              lng: data['results'][0]['geometry']['location']['lng'] };
          }
        }
        return false;
      });
    }
  });

});