define('ironsides-ui/mixins/mobile-device-detection', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Mixin;

  Mixin = Ember['default'].Mixin.create({
    isMobileDevice: (function () {
      return !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/);
    }).property()
  });

  exports['default'] = Mixin;

});