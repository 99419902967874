define('ironsides-ui/routes/timesheet/activities/new', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var TimesheetNewActivityRoute;

  TimesheetNewActivityRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    model: function model() {
      var latestTime, timesheet;
      timesheet = this.modelFor('timesheet');
      latestTime = timesheet.get('latestTime') || null;
      return this.store.createRecord('activity', {
        timesheet: timesheet,
        job: timesheet.get('lastJob'),
        start_at: latestTime,
        end_at: latestTime
      });
    },
    setupController: function setupController(controller, model) {
      var company;
      this._super(controller, model);
      company = this.get('haulers').findBy('internal', true);
      controller.set('machines', this.store.filter('machine', {
        company_id: company.id
      }, function (machine) {
        return machine.get('company.id') === company.id;
      }));
      return controller.set('jobs', this.store.filter('job', {
        'active': true
      }, function (job) {
        return job.get('active') === true;
      }));
    },
    rollback: function rollback() {
      var model;
      model = this.get('currentModel');
      if (model.get('isDirty')) {
        model.get('machineActivities').forEach(function (ma) {
          return ma.deleteRecord();
        });
        return model.deleteRecord();
      }
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('timesheet.activities');
      }
    }
  });

  exports['default'] = TimesheetNewActivityRoute;

});