define('ironsides-ui/helpers/get', ['exports', 'ember', 'ember-get-helper/helpers/get', 'ember-get-helper/helpers/get-glimmer'], function (exports, Ember, get, getGlimmerHelper) {

  'use strict';

  var forExport = null;

  if (Ember['default'].Helper) {
    forExport = getGlimmerHelper['default'];
  } else if (Ember['default'].HTMLBars.makeBoundHelper) {
    forExport = Ember['default'].HTMLBars.makeBoundHelper(get.getHelper);
  }

  exports['default'] = forExport;

});