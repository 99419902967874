define('ironsides-ui/routes/job', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var JobRoute;

  JobRoute = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('job', params.job_id);
    }
  });

  exports['default'] = JobRoute;

});