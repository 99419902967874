define('ironsides-ui/models/hauler', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Hauler;

  Hauler = DS['default'].Model.extend({
    tickets: DS['default'].hasMany('ticket', {
      async: true
    }),
    bills: DS['default'].hasMany('bill', {
      async: true
    }),
    name: DS['default'].attr('string'),
    phone: DS['default'].attr('string'),
    address: DS['default'].attr('string'),
    city: DS['default'].attr('string'),
    state: DS['default'].attr('string'),
    postal_code: DS['default'].attr('string'),
    contact_person: DS['default'].attr('string'),
    internal: DS['default'].attr('boolean')
  });

  exports['default'] = Hauler;

});