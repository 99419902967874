define('ironsides-ui/controllers/timesheet-result', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetResult;

  TimesheetResult = Ember['default'].ObjectController.extend({
    isLocked: false,
    timesheet: (function () {
      return this.store.find('timesheet', this.get('id'));
    }).property('id'),
    toggleLocked: (function () {
      var locked;
      locked = this.get('isLocked');
      return this.get('timesheet').then((function (_this) {
        return function (ts) {
          if (locked && !ts.get('locked_at')) {
            ts.set('locked_at', new Date());
            return ts.save();
          } else if (!locked && ts.get('locked_at')) {
            ts.set('locked_at', null);
            return ts.save();
          }
        };
      })(this));
    }).observes('isLocked'),
    setIsLocked: (function () {
      return this.set('isLocked', !!this.get('locked_at'));
    }).on('init')
  });

  exports['default'] = TimesheetResult;

});