define('ironsides-ui/controllers/reports/resources', ['exports', 'ember', 'ironsides-ui/mixins/ajax-download'], function (exports, Ember, AjaxDownloadMixin) {

  'use strict';

  var reportsResources;

  reportsResources = Ember['default'].Controller.extend(AjaxDownloadMixin['default'], {
    queryParams: ['userId', 'jobId', 'machineId', 'machineTypeId', 'startDate', 'endDate'],
    userId: null,
    machineId: null,
    machineTypeId: null,
    jobId: null,
    startDate: null,
    endDate: null,
    machines: (function () {
      var machineTypeId;
      machineTypeId = this.get('machineTypeId');
      if (!machineTypeId) {
        return [];
      }
      return this.store.query('machine', {
        company_id: this.get('company.id'),
        machine_type_id: machineTypeId
      });
    }).property('machineTypeId'),
    hours: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('hours').reduce(function (a, b) {
          return parseFloat(a || 0) + parseFloat(b || 0);
        }, 0);
      } else {
        return 0;
      }
    }).property('content.@each.hours'),
    machineHours: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('machine_hours').reduce(function (a, b) {
          return parseFloat(a || 0) + parseFloat(b || 0);
        }, 0);
      } else {
        return 0;
      }
    }).property('content.@each.machine_hours'),
    machineFuel: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('fuel').reduce(function (a, b) {
          return parseFloat(a || 0) + parseFloat(b || 0);
        }, 0);
      } else {
        return 0;
      }
    }).property('content.@each.fuel'),
    onroadMiles: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('onroad_miles').reduce(function (a, b) {
          return parseFloat(a || 0) + parseFloat(b || 0);
        }, 0);
      } else {
        return 0;
      }
    }).property('content.@each.onroad_miles'),
    offroadMiles: (function () {
      if (this.get('content.length')) {
        return this.get('content').mapBy('offroad_miles').reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b);
        }, 0);
      } else {
        return 0;
      }
    }).property('content.@each.offroad_miles'),
    exportDisabled: (function () {
      return !this.get('model.length');
    }).property('model.[]'),
    getReport: (function () {
      var control;
      control = this;
      this.set('isLoading', true);
      return $.ajax('/api/search/activities.json', {
        data: {
          user_id: this.get('userId'),
          job_id: this.get('jobId'),
          machine_id: this.get('machineId'),
          machine_type_id: this.get('machineTypeId'),
          start_date: this.get('startDate'),
          end_date: this.get('endDate')
        },
        headers: {
          Authorization: 'Bearer ' + this.session.get('access_token')
        }
      }).then(function (data) {
        control.set('model', data.activities);
        return control.set('isLoading', false);
      });
    }).observes('userId', 'jobId', 'machineId', 'machineTypeId', 'startDate', 'endDate'),
    actions: {
      "export": function _export() {
        return this.downloadFileAjax('/api/search/activities.csv', {
          data: {
            user_id: this.get('userId'),
            job_id: this.get('jobId'),
            machine_id: this.get('machineId'),
            machine_type_id: this.get('machineTypeId'),
            start_date: this.get('startDate'),
            end_date: this.get('endDate')
          },
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      },
      print: function print() {
        return this.downloadFileAjax('/api/search/activities.pdf', {
          dataType: 'binary',
          processData: false,
          data: {
            user_id: this.get('userId'),
            job_id: this.get('jobId'),
            machine_id: this.get('machineId'),
            machine_type_id: this.get('machineTypeId'),
            start_date: this.get('startDate'),
            end_date: this.get('endDate')
          },
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      }
    }
  });

  exports['default'] = reportsResources;

});