define('ironsides-ui/models/purchase', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Purchase;

  Purchase = DS['default'].Model.extend({
    category: DS['default'].belongsTo('purchase-category', {
      async: true
    }),
    machine: DS['default'].belongsTo('machine', {
      async: true
    }),
    timesheet: DS['default'].belongsTo('timesheet', {
      async: true
    }),
    user: DS['default'].belongsTo('user', {
      async: true
    }),
    vendor: DS['default'].belongsTo('vendor', {
      async: true
    }),
    description: DS['default'].attr('string'),
    amount: DS['default'].attr('number'),
    quantity: DS['default'].attr('number'),
    state: DS['default'].attr('string'),
    personal: DS['default'].attr('boolean')
  });

  exports['default'] = Purchase;

});