define('ironsides-ui/controllers/mill/edit', ['exports', 'ember', 'ironsides-ui/controllers/mill/form'], function (exports, Ember, MillFormController) {

	'use strict';

	var MillEdit;

	MillEdit = MillFormController['default'].extend();

	exports['default'] = MillEdit;

});