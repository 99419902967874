define('ironsides-ui/routes/reports/purchases', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PurchasesReport;

  PurchasesReport = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    setupController: function setupController(controller, model) {
      var company;
      this._super(controller, model);
      company = this.get('haulers').findBy('internal', true);
      this.store.find('machine').then(function (machines) {
        return controller.set('machines', machines.filterBy('company.id', company.get('id')).sortBy('name'));
      });
      this.store.find('vendor').then(function (vendors) {
        return controller.set('vendors', vendors.sortBy('name'));
      });
      this.store.find('user').then(function (users) {
        return controller.set('users', users.sortBy('name'));
      });
      return this.store.find('purchaseCategory').then(function (cats) {
        return controller.set('categories', cats.sortBy('name'));
      });
    }
  });

  exports['default'] = PurchasesReport;

});