define('ironsides-ui/routes/purchase-categories', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PurchaseCategoriesRoute;

  PurchaseCategoriesRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('purchase-category');
    }
  });

  exports['default'] = PurchaseCategoriesRoute;

});