define('ironsides-ui/tests/unit/routes/purchase-categories-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('route:purchase-categories', 'PurchaseCategoriesRoute', {});

  ember_qunit.test('it exists', function () {
    var route;
    route = this.subject();
    return ok(route);
  });

});