define('ironsides-ui/controllers/google-map/polygon', ['exports', 'ember', 'ironsides-ui/controllers/google-map/polyline'], function (exports, Ember, GoogleMapPolylineController) {

  'use strict';

  var computed = Ember['default'].computed;
  var alias = computed.alias;

  /**
   * @class GoogleMapPolygonController
   * @extends GoogleMapPolylineController
   */
  exports['default'] = GoogleMapPolylineController['default'].extend({
    fillColor: alias('model.fillColor'),
    fillOpacity: alias('model.fillOpacity')
  });

});