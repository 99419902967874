define('ironsides-ui/routes/user/edit', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var UserEditRoute;

  UserEditRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    actions: {
      cancel: function cancel() {
        return this.transitionTo('users');
      }
    }
  });

  exports['default'] = UserEditRoute;

});