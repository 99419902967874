define('ironsides-ui/models/material', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Material;

  Material = DS['default'].Model.extend({
    tickets: DS['default'].hasMany('ticket', {
      async: true
    }),
    name: DS['default'].attr('string')
  });

  exports['default'] = Material;

});