define('ironsides-ui/routes/material/form', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

	'use strict';

	var Route;

	Route = Ember['default'].Route.extend(RollbackMixin['default']);

	exports['default'] = Route;

});