define('ironsides-ui/routes/measurement-types/new', ['exports', 'ember', 'ironsides-ui/routes/material/form'], function (exports, Ember, MeasurementTypeFormRoute) {

  'use strict';

  var Route;

  Route = MeasurementTypeFormRoute['default'].extend({
    model: function model() {
      return this.store.createRecord('measurement-type');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('measurement-types');
      },
      saved: function saved() {
        return this.transitionTo('measurement-types');
      }
    }
  });

  exports['default'] = Route;

});