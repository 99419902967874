define('ironsides-ui/tests/helpers/flash-message.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers');
  QUnit.test('helpers/flash-message.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'helpers/flash-message.js should pass jshint.'); 
  });

});