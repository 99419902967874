define('ironsides-ui/routes/measurement-type', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Route;

  Route = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('measurement-type', params.measurement_type_id);
    }
  });

  exports['default'] = Route;

});