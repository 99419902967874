define('ironsides-ui/components/time-field', ['exports', 'ironsides-ui/components/form-field', 'moment'], function (exports, FormField, moment) {

  'use strict';

  var TimeField;

  TimeField = FormField['default'].extend({
    type: 'time',
    step: 60,
    setup: (function () {
      if (!this.get('dateBase')) {
        this.set('dateBase', new Date());
      }
      return this.updateTime();
    }).on('init'),
    dateBaseAndTime: (function () {
      var tm;
      tm = this.get('time');
      if (tm) {
        return moment['default'](moment['default'](this.get('dateBase')).format('L') + " " + tm);
      } else {
        return null;
      }
    }).property('dateBase', 'time'),
    updateValue: (function () {
      var dbat;
      dbat = this.get('dateBaseAndTime');
      if (dbat && dbat.isValid()) {
        return this.set("value", dbat.toDate());
      } else {
        return this.set("value", null);
      }
    }).observes("time"),
    updateTime: (function () {
      var time;
      time = moment['default'](this.get("value"));
      if (time.isValid()) {
        return this.set("time", time.format("HH:mm"));
      } else {
        return this.set("time", null);
      }
    }).observes("value"),
    roundTime: (function () {
      return Ember.run.debounce(this, function () {
        var coeff, dbat, roundedTime, timeVal;
        dbat = this.get('dateBaseAndTime');
        if (dbat && dbat.isValid()) {
          timeVal = dbat.toDate();
          coeff = 1000 * (this.get('step') || 60);
          roundedTime = new Date(Math.round(timeVal.getTime() / coeff) * coeff);
          if (timeVal !== roundedTime) {
            return this.set('value', roundedTime);
          }
        }
      }, 500, false);
    }).observes('dateBaseAndTime', 'step')
  });

  exports['default'] = TimeField;

});