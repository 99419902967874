define('ironsides-ui/controllers/machines', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachinesController;

  MachinesController = Ember['default'].Controller.extend({
    sortProperties: ['name'],
    sortAscending: true,
    arrangedContent: (function () {
      return this.model.sortBy(this.sortProperties);
    }).property('model.[]', 'sortProperties'),
    filteredMachines: (function () {
      return this.get('model').filterBy('company.id', this.get('company.id'));
    }).property('arrangedContent.@each.company.id', 'company.id')
  });

  exports['default'] = MachinesController;

});