define('ironsides-ui/serializers/application', ['exports', 'active-model-adapter'], function (exports, active_model_adapter) {

	'use strict';

	var Serializer;

	Serializer = active_model_adapter.ActiveModelSerializer.extend();

	exports['default'] = Serializer;

});