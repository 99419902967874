define('ironsides-ui/models/user', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var User;

  User = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    username: DS['default'].attr('string'),
    admin: DS['default'].attr('boolean'),
    password: DS['default'].attr('string')
  });

  exports['default'] = User;

});