define('ironsides-ui/controllers/machines/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachinesIndexController;

  MachinesIndexController = Ember['default'].ArrayController.extend({
    sortProperties: ['machineType.name', 'name'],
    sortAscending: true
  });

  exports['default'] = MachinesIndexController;

});