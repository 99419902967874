define('ironsides-ui/components/form-field', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['field'],
    classNameBindings: ['hasError:error', 'hasHint'],
    label: null,
    debounce: false,
    debounceWait: 300,
    hint: null,
    placeholder: null,
    "for": '',
    focus: false,
    input_id: (function () {
      return this.get('for') + new Date().getTime();
    }).property('for'),
    model: (function () {
      return this.get('parentView.model');
    }).property('parentView.model'),
    didInsertElement: function didInsertElement() {
      if (this.get('focus')) {
        return this.$().focus();
      }
    },
    init: function init() {
      this._super();
      Ember['default'].Binding.from('model.errors.' + this.get('for')).to('model_errors').connect(this);
      return Ember['default'].Binding.from('model.' + this.get('for')).to('value').connect(this);
    },
    hasError: (function () {
      return (this.get('model_errors') || []).length > 0;
    }).property('model_errors'),
    hasHint: (function () {
      return !!this.get('hint');
    }).property('hint'),
    hasLabel: (function () {
      return !!this.get('label');
    }).property('label'),
    type: (function () {
      if ((this.get('label') || '').match(/password/i)) {
        return "password";
      } else {
        return "text";
      }
    }).property('label'),
    _setValue: function _setValue(val) {
      return this.set('value', val);
    }
  });

});