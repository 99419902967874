define('ironsides-ui/controllers/mills/new', ['exports', 'ember', 'ironsides-ui/controllers/mill/form'], function (exports, Ember, MillFormController) {

	'use strict';

	var MillsNew;

	MillsNew = MillFormController['default'].extend();

	exports['default'] = MillsNew;

});