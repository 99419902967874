define('ironsides-ui/components/debounced-input', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].TextField.extend({
    debounceWait: 500, // default wait value
    fireAtStart: false, // defaults to “start at start of wait period”
    _elementValueDidChange: function _elementValueDidChange() {
      Ember['default'].run.debounce(this, this._setValue, this.debounceWait, this.fireAtStart);
    },
    _setValue: function _setValue() {
      this.set('value', this.$().val());
    }
  });

});