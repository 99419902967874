define('ironsides-ui/routes/tickets/new', ['exports', 'ironsides-ui/routes/ticket/form'], function (exports, Form) {

  'use strict';

  exports['default'] = Form['default'].extend({

    model: function model(params) {
      return this.store.createRecord('ticket', {
        date: new Date(),
        job: params['jobId'] ? this.get('jobs').findBy('id', params.jobId) : null,
        material: params['materialId'] ? this.get('materials').findBy('id', params.materialId) : null,
        mill: params['millId'] ? this.get('mills').findBy('id', params.millId) : null
      });
    },
    actions: {
      didSave: function didSave() {
        this.transitionTo('tickets');
      }
    }
  });

});