define('ironsides-ui/routes/haulers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var HaulersRoute;

  HaulersRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('hauler');
    }
  });

  exports['default'] = HaulersRoute;

});