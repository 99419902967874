define('ironsides-ui/routes/materials/new', ['exports', 'ember', 'ironsides-ui/routes/material/form'], function (exports, Ember, MaterialFormRoute) {

  'use strict';

  var Route;

  Route = MaterialFormRoute['default'].extend({
    model: function model() {
      return this.store.createRecord('material');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('materials');
      },
      saved: function saved() {
        return this.transitionTo('materials');
      }
    }
  });

  exports['default'] = Route;

});