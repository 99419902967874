define('ironsides-ui/routes/timesheet/inspections', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetInspections;

  TimesheetInspections = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('timesheet').get('inspections');
    }
  });

  exports['default'] = TimesheetInspections;

});