define('ironsides-ui/controllers/hauler/edit', ['exports', 'ember', 'ironsides-ui/controllers/hauler/form'], function (exports, Ember, HaulerFormController) {

	'use strict';

	var HaulerEdit;

	HaulerEdit = HaulerFormController['default'].extend();

	exports['default'] = HaulerEdit;

});