define('ironsides-ui/mixins/routes/modal', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Mixin;

  Mixin = Ember['default'].Mixin.create({
    renderTemplate: function renderTemplate(controller, model) {
      return this.render({
        view: 'modal',
        into: 'application',
        outlet: 'modal'
      });
    },
    actions: {
      cancel: function cancel() {
        return history.go(-1);
      },
      didSave: function didSave() {
        return history.go(-1);
      },
      saved: function saved() {
        console.log("Deprecation Notice: used action saved.  Should use action didSave");
        return this.send('didSave');
      }
    }
  });

  exports['default'] = Mixin;

});