define('ironsides-ui/tests/controllers/timesheet/activity/edit.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/timesheet/activity');
  QUnit.test('controllers/timesheet/activity/edit.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'controllers/timesheet/activity/edit.js should pass jshint.'); 
  });

});