define('ironsides-ui/routes/timesheet/purchase', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var TimesheetPurchase;

  TimesheetPurchase = Ember['default'].Route.extend(RollbackMixin['default'], {
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    model: function model(params) {
      return this.store.find('timesheet-purchase', params.purchase_id);
    },
    setupController: function setupController(controller, model) {
      var company;
      this._super(controller, model);
      controller.set('page_title', 'Edit Purchase');
      this.store.find('machine');
      company = this.get('haulers').findBy('internal', true);
      this.controllerFor('machines').set('model', this.store.filter('machine', function (machine) {
        return machine.get('company.id') === company.id;
      }));
      this.controllerFor('vendors').set('model', this.store.find('vendor'));
      return this.controllerFor('purchase_categories').set('model', this.store.find('purchase_category'));
    },
    actions: {
      didSave: function didSave() {
        return this.transitionTo('timesheet-purchases');
      },
      cancel: function cancel() {
        return this.transitionTo('timesheet-purchases');
      }
    }
  });

  exports['default'] = TimesheetPurchase;

});