define('ironsides-ui/controllers/machine-types', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MachineTypesController;

  MachineTypesController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    sortAscending: true
  });

  exports['default'] = MachineTypesController;

});