define('ironsides-ui/models/machine-type', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var MachineType;

  MachineType = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    track_fuel: DS['default'].attr('boolean'),
    track_hours: DS['default'].attr('boolean'),
    track_mileage: DS['default'].attr('boolean'),
    require_inspection: DS['default'].attr('boolean'),
    machines: DS['default'].hasMany('machine', {
      async: true
    }),
    inspectionItems: DS['default'].hasMany('inspectionItem', {
      async: true
    })
  });

  exports['default'] = MachineType;

});