define('ironsides-ui/controllers/job', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var JobController;

  JobController = Ember['default'].ObjectController.extend({
    breadCrumb: Ember['default'].computed.alias('name')
  });

  exports['default'] = JobController;

});