define('ironsides-ui/routes/mill', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Route;

  Route = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('mill', params.mill_id);
    }
  });

  exports['default'] = Route;

});