define('ironsides-ui/routes/client', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClientRoute;

  ClientRoute = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('client', params.client_id);
    }
  });

  exports['default'] = ClientRoute;

});