define('ironsides-ui/components/model-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var component;

  component = Ember['default'].Component.extend({
    tagName: 'form',
    classNames: ['ui form'],
    model: null,
    isSaving: Ember['default'].computed.alias('model.isSaving'),
    submit: function submit(e) {
      e.preventDefault();
      return this.sendAction();
    }
  });

  exports['default'] = component;

});