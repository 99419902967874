define('ironsides-ui/routes/timesheet', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetRoute;

  TimesheetRoute = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('timesheet', params.timesheet_id);
    }
  });

  exports['default'] = TimesheetRoute;

});