define('ironsides-ui/components/inspected-item', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    isSatisfactory: (function () {
      var sat = this.get('model.satisfactory');
      return sat != null && sat === true;
    }).property('model.satisfactory'),

    isUnSatisfactory: (function () {
      var sat = this.get('model.satisfactory');
      return sat != null && sat === false;
    }).property('model.satisfactory'),

    actions: {
      toggleSatisfactory: function toggleSatisfactory() {
        if (this.get('isSatisfactory')) {
          this.set('model.satisfactory', null);
        } else {
          this.set('model.satisfactory', true);
        }
      },
      toggleUnSatisfactory: function toggleUnSatisfactory() {
        if (this.get('isUnSatisfactory')) {
          this.set('model.satisfactory', null);
        } else {
          this.set('model.satisfactory', false);
        }
      }
    }
  });

});