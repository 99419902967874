define('ironsides-ui/models/trip', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Trip;

  Trip = DS['default'].Model.extend({
    activity: DS['default'].belongsTo('activity'),
    machine: DS['default'].belongsTo('machine'),
    odometer_start: DS['default'].attr('number'),
    odometer_end: DS['default'].attr('number'),
    state: DS['default'].attr('string'),
    offroad: DS['default'].attr('boolean'),
    miles: (function () {
      var oe, os;
      os = this.get('odometer_start');
      oe = this.get('odometer_end');
      if (os && oe) {
        return oe - os;
      } else {
        return 0;
      }
    }).property('odometer_start', 'odometer_end'),
    updateMachineOdometer: (function () {
      var endOdo, modo;
      modo = this.get('machine.odometer');
      endOdo = this.get('odometer_end');
      if (modo && endOdo) {
        if (modo < endOdo) {
          return this.set('machine.odometer', endOdo);
        }
      }
    }).on('didCreate', 'didUpdate'),
    setStartOdometer: (function () {
      var lastTrip, machine_odo;
      if (this.get('isNew')) {
        if (this.get('activity')) {
          lastTrip = this.get('activity.trips').sortBy('odometer_end').get('lastObject');
        } else {
          lastTrip = null;
        }
        if (lastTrip && lastTrip !== this) {
          this.set('odometer_start', lastTrip.get('odometer_end'));
          this.set('odometer_end', lastTrip.get('odometer_end'));
        } else {
          if (!this.get('odometer_start')) {
            machine_odo = this.get('machine.odometer');
            if (machine_odo) {
              this.set('odometer_start', machine_odo);
              this.set('odometer_end', machine_odo);
            } else {
              this.set('odometer_start', null);
              this.set('odometer_end', null);
            }
          }
        }
      }
    }).observes('activity', 'machine')
  });

  exports['default'] = Trip;

});