define('ironsides-ui/routes/job/form', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var JobFormRoute;

  JobFormRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return this.controllerFor('clients').set('model', this.store.find('client'));
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('job', this.currentModel);
      }
    }
  });

  exports['default'] = JobFormRoute;

});