define('ironsides-ui/helpers/money', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (value) {
    if (value) {
      return "$" + parseFloat(value).toFixed(2).replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });
    } else {
      return value;
    }
  });

});