define('ironsides-ui/tests/routes/client/index.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/client');
  QUnit.test('routes/client/index.js should pass jshint', function(assert) { 
    assert.expect(1);
    assert.ok(true, 'routes/client/index.js should pass jshint.'); 
  });

});