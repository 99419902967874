define('ironsides-ui/models/inspected-item', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var InspectedItem;

  InspectedItem = DS['default'].Model.extend({
    inspection: DS['default'].belongsTo('inspection', {
      async: true
    }),
    inspection_item: DS['default'].belongsTo('inspectionItem', {
      async: true
    }),
    satisfactory: DS['default'].attr('boolean'),
    note: DS['default'].attr('string')
  });

  exports['default'] = InspectedItem;

});