define('ironsides-ui/tests/unit/controllers/timesheets-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('controller:timesheets', 'TimesheetsController', {});

  ember_qunit.test('it exists', function () {
    var controller;
    controller = this.subject();
    return ok(controller);
  });

});