define('ironsides-ui/services/local-cache', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var stringify = JSON.stringify;
  var parse = JSON.parse;

  function _setItem(key, value) {
    localStorage.setItem(key, stringify(value));
  }

  function _getItem(key) {
    var result = localStorage.getItem(key);
    if (result) {
      return parse(result);
    }
  }

  exports['default'] = Ember['default'].Service.extend({
    namespace: 'ember-local-storage',
    setItem: function setItem(key, object) {
      var ttlOptions = arguments[2];

      if (ttlOptions) {
        this._setTTLKey(key, ttlOptions);
      }

      _setItem(this._namespacedKey(key), object);
    },
    getItem: function getItem(key) {
      return _getItem(this._namespacedKey(key));
    },
    keyExpired: function keyExpired(key, now) {
      var ttl = this.getItem('_ttl_' + key);
      if (ttl) {
        var expiry = new Date(ttl.lastUpdated).setTime(new Date(ttl.lastUpdated).getTime() + ttl.ttl);

        now = now || new Date();

        return now > expiry;
      }
      return true;
    },
    _setTTLKey: function _setTTLKey(key, ttlOptions) {
      var dateTime = new Date();
      _setItem(this._namespacedKey('_ttl_' + key), { ttl: ttlOptions.ttl, lastUpdated: dateTime });
    },
    _namespacedKey: function _namespacedKey(key) {
      return this.get('namespace') + ('.' + key);
    }
  });

});