define('ironsides-ui/controllers/vendors', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var VendorsController;

  VendorsController = Ember['default'].ArrayController.extend({
    sortProperties: ['name']
  });

  exports['default'] = VendorsController;

});