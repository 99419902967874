define('ironsides-ui/controllers/measurement-types/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MeasurementTypesIndexController;

  MeasurementTypesIndexController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    sortAscending: true
  });

  exports['default'] = MeasurementTypesIndexController;

});