define('ironsides-ui/routes/jobs/new', ['exports', 'ironsides-ui/routes/job/form'], function (exports, JobFormRoute) {

  'use strict';

  var NewJobRoute;

  NewJobRoute = JobFormRoute['default'].extend({
    model: function model() {
      return this.store.createRecord('job');
    }
  });

  exports['default'] = NewJobRoute;

});