define('ironsides-ui/routes/purchase-categories/new', ['exports', 'ironsides-ui/mixins/routes/rollback'], function (exports, RollbackMixin) {

  'use strict';

  var PurchaseCategoriesNewRoute;

  PurchaseCategoriesNewRoute = Ember.Route.extend(RollbackMixin['default'], {
    model: function model() {
      return this.store.createRecord('purchase_category');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('purchase_categories');
      }
    }
  });

  exports['default'] = PurchaseCategoriesNewRoute;

});