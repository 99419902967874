define('ironsides-ui/controllers/ticket/form', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Controller.extend({
    machines: Em['default'].computed('model.hauler', function () {
      var hauler_id = this.get('model.hauler.id');
      if (hauler_id) {
        return this.store.query('machine', { company_id: hauler_id });
      } else {
        return [];
      }
    }),

    actions: {
      saveTicket: function saveTicket() {
        this.model.save().then((function () {
          this.send('didSave');
        }).bind(this))['catch'](function () {});
      },
      setJob: function setJob(val) {
        this.set('model.job', val);
        this.set('jobId', val ? val.get('id') : null);
      },
      setMill: function setMill(val) {
        this.set('model.mill', val);
        this.set('millId', val ? val.get('id') : null);
      },
      setMaterial: function setMaterial(val) {
        this.set('model.material', val);
        this.set('materialId', val ? val.get('id') : null);
      }
    }

  });

});