define('ironsides-ui/controllers/reports/purchases', ['exports', 'ember', 'ironsides-ui/mixins/ajax-download'], function (exports, Ember, AjaxDownloadMixin) {

  'use strict';

  var reportsPurchases;

  reportsPurchases = Ember['default'].Controller.extend(AjaxDownloadMixin['default'], {
    queryParams: ['userId', 'jobId', 'vendorId', 'machineId', 'categoryId', 'startDate', 'endDate'],
    userId: null,
    machineId: null,
    jobId: null,
    vendorId: null,
    categoryId: null,
    startDate: null,
    endDate: null,
    total: (function () {
      if (this.get('model.length')) {
        return this.get('model').mapBy('amount').reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b);
        }, 0);
      } else {
        return 0;
      }
    }).property('model.@each.amount'),
    exportDisabled: (function () {
      return !this.get('model.length');
    }).property('model.[]'),
    filterData: (function () {
      return {
        user_id: this.get('userId'),
        vendor_id: this.get('vendorId'),
        job_id: this.get('jobId'),
        machine_id: this.get('machineId'),
        category_id: this.get('categoryId'),
        start_date: this.get('startDate'),
        end_date: this.get('endDate'),
        personal_only: this.get('personalFunds')
      };
    }).property('userId', 'vendorId', 'machineId', 'categoryId', 'startDate', 'endDate', 'personalFunds'),
    getReport: (function () {
      var control;
      control = this;
      this.set('isLoading', true);
      return $.ajax('/api/search/purchases.json', {
        data: this.get('filterData'),
        headers: {
          Authorization: 'Bearer ' + this.session.get('access_token')
        }
      }).then(function (data) {
        control.set('model', data.purchases);
        return control.set('isLoading', false);
      });
    }).observes('userId', 'vendorId', 'machineId', 'categoryId', 'startDate', 'endDate', 'personalFunds'),
    actions: {
      "export": function _export() {
        return this.downloadFileAjax('/api/search/purchases.csv', {
          data: this.get('filterData'),
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      },
      print: function print() {
        return this.downloadFileAjax('/api/search/purchases.pdf', {
          dataType: 'binary',
          processData: false,
          data: this.get('filterData'),
          headers: {
            Authorization: 'Bearer ' + this.session.get('access_token')
          }
        });
      }
    }
  });

  exports['default'] = reportsPurchases;

});