define('ironsides-ui/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, Ember, ApplicationRouteMixin) {

  'use strict';

  var route;

  route = Ember['default'].Route.extend(ApplicationRouteMixin['default'], {
    actions: {
      error: function error(status) {
        if (status === true) {
          this.transitionTo('login');
        }
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      openModal: function openModal(name, model) {
        return this.send('showModel', name, model);
      },
      showModal: function showModal(name, model) {
        return this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },
      closeModal: function closeModal() {
        return this.send('removeModal');
      },
      removeModal: function removeModal() {
        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      willTransition: function willTransition() {
        return this.controllerFor('application').send('closeMenu');
      }
    }
  });

  exports['default'] = route;

});