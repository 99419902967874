define('ironsides-ui/components/machine-activity-fields', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    model: null,
    timesheet: Em['default'].computed.alias('model.activity.timesheet'),
    machines: [],
    store: Em['default'].inject.service('store'),
    session: Em['default'].inject.service('session'),

    machineDidChange: (function () {
      Em['default'].run.once(this, 'promptInspection');
    }).observes('model.machine'),

    promptInspection: function promptInspection() {
      if (this.get('model.machine')) {
        var machine = this.get('model.machine');
        if (machine.get('id')) {
          machine.get('machine_type').then((function (mt) {
            if (mt.get('require_inspection')) {
              this.get('timesheet.inspections').then((function (inspections) {
                if (!inspections.findBy('machine.id', machine.get('id'))) {
                  var newInspection = this.get('store').createRecord('inspection', {
                    time: this.get('start_at') || new Date(),
                    machine: machine,
                    timesheet: this.get('timesheet'),
                    user: this.get('session.currentUser')
                  });
                  this.get('model').set('inspection', newInspection);
                  this.set('inspection', newInspection);
                }
              }).bind(this));
            }
          }).bind(this));
        }
      }
    },
    actions: {
      remove: function remove() {
        this.sendAction('removeMachineActivity', this.get('model'));
      },
      inspectionCompleted: function inspectionCompleted() {
        this.set('inspection', undefined);
      },
      inspectionCancelled: function inspectionCancelled() {
        this.get('model').set('machine', undefined);
        this.get('inspection').deleteRecord();
        this.set('inspection', undefined);
      }
    }
  });

});