define('ironsides-ui/controllers/timesheet', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimesheetController;

  TimesheetController = Ember['default'].ObjectController.extend({
    out_of_town: false,
    belongsToCurrentUser: (function () {
      return this.get('user.id') === this.session.get('currentUserId').toString();
    }).property('user', 'session.currentUser'),
    breadCrumb: (function () {
      return this.get('date').toDateString();
    }).property('date'),
    activities: (function () {
      return this.get('model.activities').sortBy('end_at');
    }).property('model.activities.@each'),
    setOutOfTown: (function () {
      return this.set('out_of_town', this.model.get('out_of_town'));
    }).observes('model.out_of_town'),
    watchOutOfTown: (function () {
      this.model.set('out_of_town', this.get('out_of_town'));
      return this.model.save();
    }).observes('out_of_town'),
    actions: {
      deleteActivity: function deleteActivity(a) {
        if (confirm('Delete?')) {
          a.deleteRecord();
          return a.save();
        }
      }
    }
  });

  exports['default'] = TimesheetController;

});