define('ironsides-ui/routes/jobs', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var JobsRoute;

  JobsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('job');
    }
  });

  exports['default'] = JobsRoute;

});