define('ironsides-ui/controllers/google-map/polyline-path', ['exports', 'ember', 'ember-google-map/mixins/google-array', 'ember-google-map/core/helpers'], function (exports, Ember, GoogleArrayMixin, helpers) {

  'use strict';

  var computed = Ember['default'].computed;

  /**
   * @class GoogleMapPolylinePathController
   * @extends Ember.ArrayController
   */
  exports['default'] = Ember['default'].ArrayController.extend(GoogleArrayMixin['default'], {
    model: computed.alias('parentController.path'),
    googleItemFactory: helpers['default']._latLngToGoogle,
    emberItemFactory: function emberItemFactory(googleLatLng) {
      return Ember['default'].Object.create(helpers['default']._latLngFromGoogle(googleLatLng));
    },
    observeEmberProperties: ['lat', 'lng']
  });

});