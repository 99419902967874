define('ironsides-ui/controllers/machine-type/inspection-items', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var InspectionItems;

  InspectionItems = Ember['default'].ArrayController.extend({
    needs: ['machineType'],
    sortProperties: ['order'],
    modalModel: null,
    actions: {
      cancelModal: function cancelModal() {
        this.get('modalModel').rollback();
        return this.set('modalModel', null);
      },
      newItem: function newItem() {
        var inspectionItem;
        inspectionItem = this.store.createRecord('inspectionItem', {
          machineType: this.get('controllers.machineType').get('model')
        });
        return this.set('modalModel', inspectionItem);
      },
      editItem: function editItem(item) {
        return this.set('modalModel', item);
      },
      saveItem: function saveItem() {
        return this.get('modalModel').save().then((function (_this) {
          return function () {
            return _this.send('cancelModal');
          };
        })(this), function () {});
      },
      moveUp: function moveUp(item) {
        item.set('order', (item.get('order') || -1) - 1.1);
        return Ember['default'].run.debounce(this, this.reorderItems, 1500, false);
      },
      moveDown: function moveDown(item) {
        item.set('order', item.get('order') + 1.1);
        return Ember['default'].run.debounce(this, this.reorderItems, 1500, false);
      }
    },
    reorderItems: function reorderItems() {
      return this.get('arrangedContent').forEach(function (item, index) {
        item.set('order', index);
        if (item.get('isDirty')) {
          return item.save();
        }
      });
    }
  });

  exports['default'] = InspectionItems;

});