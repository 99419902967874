define('ironsides-ui/models/machine-activity', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var MachineActivity;

  MachineActivity = DS['default'].Model.extend({
    activity: DS['default'].belongsTo('activity', {
      async: true
    }),
    machine: DS['default'].belongsTo('machine', {
      async: true
    }),
    trips: DS['default'].hasMany('trip', {
      async: true
    }),
    hour_meter_start: DS['default'].attr(),
    hour_meter_end: DS['default'].attr(),
    fuel: DS['default'].attr(),
    odometer_start: DS['default'].attr('number'),
    odometer_end: DS['default'].attr('number'),
    state: DS['default'].attr('string'),
    offroad: DS['default'].attr('boolean'),
    hours: (function () {
      var mhours;
      mhours = (this.get('hour_meter_end') || this.get('hour_meter_start') || 0) - (this.get('hour_meter_start') || 0);
      if (mhours <= 0) {
        return this.get('activity.hours');
      } else {
        return mhours;
      }
    }).property('hour_meter_start', 'hour_meter_end', 'activity.hours'),
    miles: (function () {
      var oe, os;
      os = this.get('odometer_start');
      oe = this.get('odometer_end');
      if (os && oe) {
        return oe - os;
      } else {
        return 0;
      }
    }).property('odometer_start', 'odometer_end'),
    updateMachineOdometer: (function () {
      var endOdo, modo;
      modo = this.get('machine.odometer');
      endOdo = this.get('odometer_end');
      if (modo && endOdo) {
        if (modo < endOdo) {
          return this.set('machine.odometer', endOdo);
        }
      }
    }).on('didCreate', 'didUpdate'),
    setStartOdometer: (function () {
      var lastTrip, machine_odo;
      if (this.get('isNew')) {
        if (this.get('activity')) {
          lastTrip = (this.get('activity.machineActivities') || []).sortBy('odometer_end').get('lastObject');
        } else {
          lastTrip = null;
        }
        if (lastTrip && lastTrip !== this) {
          this.set('odometer_start', lastTrip.get('odometer_end'));
          this.set('odometer_end', lastTrip.get('odometer_end'));
        } else {
          if (!this.get('odometer_start')) {
            machine_odo = this.get('machine.odometer');
            if (machine_odo) {
              this.set('odometer_start', machine_odo);
              this.set('odometer_end', machine_odo);
            } else {
              this.set('odometer_start', null);
              this.set('odometer_end', null);
            }
          }
        }
      }
    }).observes('activity', 'machine'),
    updateMachineHourmeter: (function () {
      var endHour, mhour;
      mhour = this.get('machine.hourmeter');
      endHour = this.get('hourmeter_end');
      if (mhour && endHour) {
        if (mhour < endHour) {
          return this.set('machine.hourmeter', endHour);
        }
      }
    }).on('didCreate', 'didUpdate'),
    setStartHourmeter: (function () {
      var lastTrip, machine_hour;
      if (this.get('isNew')) {
        if (this.get('activity')) {
          lastTrip = (this.get('activity.machineActivities') || []).sortBy('hour_meter_end').get('lastObject');
        } else {
          lastTrip = null;
        }
        if (lastTrip && lastTrip !== this) {
          this.set('hour_meter_start', lastTrip.get('hour_meter_end'));
        } else {
          if (!this.get('hour_meter_start')) {
            machine_hour = this.get('machine.hourmeter');
            if (machine_hour) {
              this.set('hour_meter_start', machine_hour);
            } else {
              this.set('hour_meter_start', null);
            }
          }
        }
      }
    }).observes('activity', 'machine')
  });

  exports['default'] = MachineActivity;

});