define('ironsides-ui/routes/vendors/new', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var NewVendorRoute;

  NewVendorRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    model: function model() {
      return this.store.createRecord('vendor');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('page_title', 'New Vendor');
    },
    actions: {
      didSave: function didSave() {
        return this.transitionTo('vendors');
      },
      cancel: function cancel() {
        return this.transitionTo('vendors');
      }
    }
  });

  exports['default'] = NewVendorRoute;

});