define('ironsides-ui/controllers/google-map/markers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var computed = Ember['default'].computed;

  /**
   * @class GoogleMapMarkersController
   * @extends Ember.ArrayController
   */
  exports['default'] = Ember['default'].ArrayController.extend({
    itemController: computed.alias('parentController.markerController'),
    model: computed.alias('parentController.markers')
  });

});