define('ironsides-ui/routes/client/edit', ['exports', 'ironsides-ui/routes/client/form'], function (exports, ClientForm) {

  'use strict';

  var ClientEditRoute;

  ClientEditRoute = ClientForm['default'].extend({
    actions: {
      cancel: function cancel() {
        return this.transitionTo('client', this.currentModel);
      },
      saved: function saved() {
        return this.transitionTo('client', this.currentModel);
      }
    }
  });

  exports['default'] = ClientEditRoute;

});