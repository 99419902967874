define('ironsides-ui/routes/haulers/new', ['exports', 'ember', 'ironsides-ui/routes/hauler/form'], function (exports, Ember, HaulerFormRoute) {

  'use strict';

  var Route;

  Route = HaulerFormRoute['default'].extend({
    model: function model() {
      return this.store.createRecord('hauler');
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('haulers');
      },
      saved: function saved() {
        return this.transitionTo('haulers');
      }
    }
  });

  exports['default'] = Route;

});