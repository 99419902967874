define('ironsides-ui/controllers/tickets/index', ['exports', 'ember', 'moment'], function (exports, Em, moment) {

  'use strict';

  exports['default'] = Em['default'].Controller.extend({
    queryParams: ['date'],
    date: moment['default']().startOf('day'),
    arrangedContent: Em['default'].computed('model.@each.created_at', function () {
      return this.get('model').sortBy('created_at').reverse();
    })
  });

});