define('ironsides-ui/routes/timesheet/activity/edit', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var tsActivityEdit;

  tsActivityEdit = Ember['default'].Route.extend(RollbackMixin['default'], {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('jobs', this.store.filter('job', {
        'active': true
      }, function (job) {
        return job.get('active') === true;
      }));
      return controller.set('machines', this.store.find('machine'));
    },
    rollback: function rollback() {
      var model;
      model = this.get('currentModel');
      if (model.get('isDirty')) {
        model.get('machineActivities').forEach(function (ma) {
          return ma.rollback();
        });
        return model.rollback();
      }
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('timesheet.activities', this.modelFor('timesheet'));
      }
    }
  });

  exports['default'] = tsActivityEdit;

});