define('ironsides-ui/controllers/jobs/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var JobsIndex;

  JobsIndex = Ember['default'].ArrayController.extend({
    needs: ['jobs'],
    sortProperties: ['name'],
    jobList: Ember['default'].computed.alias('controllers.jobs.jobList'),
    activeOnly: Ember['default'].computed.alias('controllers.jobs.activeOnly'),
    actions: {
      deleteJob: function deleteJob(job) {
        if (confirm('Delete forever?')) {
          return job.destroyRecord();
        }
      }
    }
  });

  exports['default'] = JobsIndex;

});