define('ironsides-ui/components/map-field', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    latitude: 0,
    longitude: 0,
    centerLatitude: 0,
    centerLongitude: 0,
    address: null,
    zoom: 11,
    isDraggable: true,
    markers: [],

    lat: Em['default'].computed.alias('latitude'),
    lng: Em['default'].computed.alias('longitude'),

    isMapable: (function () {
      return !!(this.get('lat') && this.get('lng'));
    }).property('lat', 'lng'),

    didInitAttrs: function didInitAttrs() {
      this.set('markers', [this]);
      this.setCenter();
    },

    setCenter: (function () {
      Em['default'].run.debounce(this, (function () {
        this.set('centerLatitude', this.get('lat'));
        this.set('centerLongitude', this.get('lng'));
      }).bind(this), 2000);
    }).observes('lat', 'lng')

  });

});