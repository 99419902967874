define('ironsides-ui/controllers/ticket', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    session: Ember['default'].inject.service('session'),
    mayEdit: Ember['default'].computed('session.currentUser.admin', 'session.currentUser.id', 'model.user.id', function () {
      if (this.get('session.currentUser.admin')) {
        return true;
      } else if (this.get('session.currentUser.id') === this.get('model.user.id')) {
        return true;
      } else {
        return false;
      }
    })
  });

});