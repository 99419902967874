define('ironsides-ui/routes/timesheets', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthMixin) {

  'use strict';

  var TimesheetsRoute;

  TimesheetsRoute = Ember['default'].Route.extend(AuthMixin['default'], {
    beforeModel: function beforeModel() {
      return this.store.findAll('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    model: function model() {
      var user_id;
      user_id = this.session.get('currentUserId');
      return this.store.filter('timesheet', {
        user_id: user_id
      }, (function (_this) {
        return function (record) {
          return record.get('user.id') === user_id.toString() && !record.get('isLocked');
        };
      })(this));
    },
    setupController: function setupController(controller, model) {
      var company;
      controller.set('model', model);
      this.controllerFor('jobs').set('model', this.store.find('job'));
      this.store.find('machine');
      company = this.get('haulers').findBy('internal', true);
      return this.controllerFor('machines').set('model', this.store.filter('machine', function (machine) {
        return machine.get('company.id') === company.id;
      }));
    }
  });

  exports['default'] = TimesheetsRoute;

});