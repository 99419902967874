define('ironsides-ui/adapters/application', ['exports', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, ActiveModelAdapter, DataAdapterMixin) {

  'use strict';

  exports['default'] = ActiveModelAdapter['default'].extend(DataAdapterMixin['default'], {
    namespace: 'api',
    coalesceFindRequests: true,
    authorizer: 'authorizer:oauth2',

    handleResponse: function handleResponse(status) {
      if (status === 401) {
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate();
          return true;
        }
      }
      return this._super.apply(this, arguments);
    }
  });

});