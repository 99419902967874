define('ironsides-ui/controllers/jobs', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var JobsController;

  JobsController = Ember['default'].ArrayController.extend({
    breadCrumb: 'jobs',
    activeOnly: true,
    sortProperties: ['name'],
    activeJobs: (function () {
      return this.get('arrangedContent').filterBy('active', true);
    }).property('arrangedContent.@each.active'),
    jobList: (function () {
      if (this.get('activeOnly')) {
        return this.get('activeJobs');
      } else {
        return this.get('arrangedContent');
      }
    }).property('activeOnly', 'arrangedContent.@each.active')
  });

  exports['default'] = JobsController;

});