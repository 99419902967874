define('ironsides-ui/controllers/mills/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MillsIndexController;

  MillsIndexController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    sortAscending: true
  });

  exports['default'] = MillsIndexController;

});