define('ironsides-ui/routes/job/edit', ['exports', 'ironsides-ui/routes/job/form'], function (exports, JobFormRoute) {

	'use strict';

	var EditJobRoute;

	EditJobRoute = JobFormRoute['default'].extend();

	exports['default'] = EditJobRoute;

});