define('ironsides-ui/tests/unit/controllers/machine-types-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('controller:machine-types', 'MachineTypesController', {});

  ember_qunit.test('it exists', function () {
    var controller;
    controller = this.subject();
    return ok(controller);
  });

});