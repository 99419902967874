define('ironsides-ui/initializers/session', ['exports'], function (exports) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, app) {
    app.inject('controller', 'session', 'service:session');
    app.inject('route', 'session', 'service:session');
  }

  exports['default'] = {
    name: 'session',
    initialize: initialize
  };

});