define('ironsides-ui/serializers/timesheet', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var TimesheetSerializer;

  TimesheetSerializer = DS['default'].ActiveModelSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
    attrs: {
      activities: {
        deserialize: 'records'
      },
      purchases: {
        deserialize: 'records'
      }
    }
  });

  exports['default'] = TimesheetSerializer;

});