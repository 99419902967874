define('ironsides-ui/models/activity', ['exports', 'ember-data', 'moment'], function (exports, DS, moment) {

  'use strict';

  var Activity;

  Activity = DS['default'].Model.extend({
    timesheet: DS['default'].belongsTo('timesheet', {
      async: true
    }),
    job: DS['default'].belongsTo('job', {
      async: true
    }),
    machineActivities: DS['default'].hasMany('machine-activity', {
      async: true
    }),
    description: DS['default'].attr('string'),
    start_at: DS['default'].attr('date'),
    end_at: DS['default'].attr('date'),
    machines: (function () {
      return this.get('machineActivities').mapBy('machine');
    }).property('machineActivities.@each.machine'),
    machineHours: (function () {
      return this.get('machineActivities').mapBy('hours').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('machineActivities.@each.hours'),
    fuel: (function () {
      return this.get('machineActivities').mapBy('fuel').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('machineActivities.@each.fuel'),
    offroadMiles: (function () {
      if (this.get('machineActivities.content.length')) {
        return this.get('machineActivities').filter(function (t) {
          return !!t.get('offroad');
        }).mapBy('miles').reduce(function (a, b) {
          return a + b;
        }, 0);
      } else {
        return 0;
      }
    }).property('machineActivities.@each.miles', 'machineActivities.@each.offroad'),
    onroadMiles: (function () {
      if (this.get('machineActivities.content.length')) {
        return this.get('machineActivities').filter(function (t) {
          return !t.get('offroad');
        }).mapBy('miles').reduce(function (a, b) {
          return a + b;
        }, 0);
      } else {
        return 0;
      }
    }).property('machineActivities.@each.miles', 'machineActivities.@each.offroad'),
    miles: (function () {
      return this.get('machineActivities').mapBy('miles').reduce(function (a, b) {
        return Number(a || 0) + Number(b || 0);
      }, 0);
    }).property('machineActivities.@each.miles'),
    hours: (function () {
      var et, hours, st;
      st = this.get('start_at');
      et = this.get('end_at');
      if (st && et) {
        hours = (et.getTime() - st.getTime()) / 1000 / 60 / 60;
        return (Math.round(hours * 4) / 4).toFixed(2);
      } else {
        return 0;
      }
    }).property('start_at', 'end_at')
  });

  exports['default'] = Activity;

});