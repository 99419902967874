define('ironsides-ui/controllers/google-map/info-windows', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var computed = Ember['default'].computed;

  /**
   * @class GoogleMapInfoWindowsController
   * @extends Ember.ArrayController
   */
  exports['default'] = Ember['default'].ArrayController.extend({
    itemController: computed.alias('parentController.infoWindowController'),
    model: computed.alias('parentController.infoWindows')
  });

});