define('ironsides-ui/routes/timesheet-activity/edit', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var tsActivityEdit;

  tsActivityEdit = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.store.find('hauler').then((function (_this) {
        return function (haulers) {
          return _this.set('haulers', haulers);
        };
      })(this));
    },
    setupController: function setupController(controller, model) {
      var company;
      this._super(controller, model);
      this.controllerFor('jobs').set('model', this.store.find('job'));
      this.store.find('machine');
      company = this.get('haulers').findBy('internal', true);
      return this.controllerFor('machines').set('model', this.store.filter('machine', function (machine) {
        return machine.get('company.id') === company.id;
      }));
    },
    rollback: function rollback() {
      var model;
      model = this.get('currentModel');
      if (model.get('isDirty')) {
        model.get('machineActivities').forEach(function (ma) {
          return ma.rollback();
        });
        return model.rollback();
      }
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('timesheet', this.modelFor('timesheet'));
      }
    }
  });

  exports['default'] = tsActivityEdit;

});