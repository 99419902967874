define('ironsides-ui/tests/unit/routes/machine-types-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('route:machine-types', 'MachineTypesRoute', {});

  ember_qunit.test('it exists', function () {
    var route;
    route = this.subject();
    return ok(route);
  });

});