define('ironsides-ui/controllers/bills/form', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Controller.extend({
    payment: null,

    actions: {
      saveBill: function saveBill() {
        this.model.save().then((function () {
          var saves = this.model.get('billItems').map(function (item) {
            return item.save();
          });
          this.model.get('payments').forEach(function (p) {
            saves.pushObject(p.save());
          });
          Em['default'].RSVP.allSettled(saves).then((function () {
            if (!saves.anyBy('isRejected')) {

              this.send('didSave');
            }
          }).bind(this), function () {});
        }).bind(this), function () {});
      },
      addItem: function addItem() {
        this.store.createRecord('bill-item', {
          bill: this.model
        });
      },
      removeItem: function removeItem(item) {
        if (confirm('Delete?')) {
          item.deleteRecord();
        }
      },
      addPayment: function addPayment() {
        this.set('payment', this.store.createRecord('payment', {
          bill: this.model,
          date: new Date()
        }));
      },
      cancelPayment: function cancelPayment() {
        this.get('payment').deleteRecord();
        this.set('payment', null);
      },
      paymentOk: function paymentOk() {
        this.set('payment', null);
      },
      removePayment: function removePayment(payment) {
        if (confirm('Delete?')) {
          payment.deleteRecord();
        }
      }
    }
  });

});