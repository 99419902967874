define('ironsides-ui/routes/timesheets/new', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Ember, RollbackMixin) {

  'use strict';

  var TimesheetsNewRoute;

  TimesheetsNewRoute = Ember['default'].Route.extend(RollbackMixin['default'], {
    beforeModel: function beforeModel() {
      return this.session.get('currentUser');
    },
    model: function model() {
      return this.store.createRecord('timesheet', {
        user: this.session.get('currentUser'),
        date: new Date()
      });
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo('timesheets');
      },
      didSave: function didSave() {
        return this.transitionTo('timesheet.activities', this.currentModel);
      }
    }
  });

  exports['default'] = TimesheetsNewRoute;

});