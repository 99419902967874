define('ironsides-ui/models/ticket', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    hauler: DS['default'].belongsTo('hauler', {
      async: true
    }),
    mill: DS['default'].belongsTo('mill', {
      async: true
    }),
    measurement: DS['default'].belongsTo('measurement-type', {
      async: true
    }),
    material: DS['default'].belongsTo('material', {
      async: true
    }),
    user: DS['default'].belongsTo('user', {
      async: true
    }),
    truck: DS['default'].belongsTo('machine', {
      async: true
    }),
    trailer: DS['default'].belongsTo('machine', {
      async: true
    }),
    job: DS['default'].belongsTo('job', {
      async: true
    }),
    date: DS['default'].attr('date'),
    number: DS['default'].attr('string'),
    quantity: DS['default'].attr('number'),
    driver_name: DS['default'].attr('string'),
    truck_rate: DS['default'].attr('number'),
    load_paid: DS['default'].attr('number'),
    created_at: DS['default'].attr('date'),
    locked_at: DS['default'].attr('date'),

    isLocked: Ember['default'].computed('locked_at', function (key, value) {
      if (arguments.length > 1) {
        if (value) {
          this.set('locked_at', new Date());
        } else {
          this.set('locked_at', null);
        }
      }
      return !Ember['default'].isEmpty(this.get('locked_at'));
    }),

    mill_rate: (function () {
      if (this.get('load_paid') && this.get('quantity')) {
        return Math.round(this.get('load_paid') / this.get('quantity') * 100) / 100;
      }
    }).property('load_paid', 'quantity'),
    load_profit: (function () {
      if (this.get('load_paid') && this.get('truck_rate')) {
        return Math.round((this.get('load_paid') - this.get('truck_rate')) * 100) / 100;
      }
    }).property('load_paid', 'truck_rate')
  });

});