define('ironsides-ui/controllers/materials/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MaterialsIndexController;

  MaterialsIndexController = Ember['default'].ArrayController.extend({
    sortProperties: ['name'],
    sortAscending: true
  });

  exports['default'] = MaterialsIndexController;

});