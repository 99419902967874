define('ironsides-ui/routes/users', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var UsersRoute;

  UsersRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('user');
    }
  });

  exports['default'] = UsersRoute;

});