define('ironsides-ui/routes/ticket/form', ['exports', 'ember', 'ironsides-ui/mixins/routes/rollback'], function (exports, Em, RollbackMixin) {

  'use strict';

  exports['default'] = Em['default'].Route.extend(RollbackMixin['default'], {

    beforeModel: function beforeModel() {
      var route = this;
      return Em['default'].RSVP.all([this.store.findAll('material').then(function (materials) {
        route.set('materials', materials.sortBy('name'));
      }), this.store.findAll('mill').then(function (mills) {
        route.set('mills', mills.sortBy('name'));
      }), this.store.query('job', { active: true }).then(function (jobs) {
        route.set('jobs', jobs.filterBy('active', true).sortBy('name'));
      })]);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('hauler').then(function (haulers) {
        controller.set('haulers', haulers.sortBy('name'));
      });

      controller.set('jobs', this.get('jobs'));
      controller.set('mills', this.get('mills'));
      controller.set('materials', this.get('materials'));
    }
  });

});