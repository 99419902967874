define('ironsides-ui/components/dropdown-item', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports['default'] = Em['default'].Component.extend({
    tagName: 'li',
    classNames: ['ui', 'dropdown', 'item'],
    didInsertElement: function didInsertElement() {
      this.$().dropdown();
    }
  });

});