define('ironsides-ui/views/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ApplicationView;

  ApplicationView = Ember['default'].View.extend({
    classNameBindings: ['menuOn'],
    menuOn: (function () {
      return this.get('controller.menuOn');
    }).property('controller.menuOn')
  });

  exports['default'] = ApplicationView;

});