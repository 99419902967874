define('ironsides-ui/components/save-button', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    icon: 'save',
    text: 'Save',
    model: Ember['default'].computed.alias('parentView.model'),
    isSaving: Ember['default'].computed.alias('model.isSaving'),
    actions: {
      submit: function submit() {
        this.sendAction();
      }
    }
  });

});