define('ironsides-ui/routes/reports/tickets', ['exports', 'ember', 'moment'], function (exports, Em, moment) {

  'use strict';

  exports['default'] = Em['default'].Route.extend({
    queryParams: {
      number: { refreshModel: true },
      startDate: { refreshModel: true },
      endDate: { refreshModel: true },
      jobId: { refreshModel: true },
      millId: { refreshModel: true },
      materialId: { refreshModel: true },
      haulerId: { refreshModel: true },
      userId: { refreshModel: true },
      invoice: { refreshModel: true },
      lockedStatus: { refreshModel: true }
    },
    model: function model(params) {
      var serverParams = {
        number: params['number'],
        job_id: params['jobId'],
        mill_id: params['millId'],
        material_id: params['materialId'],
        hauler_id: params['haulerId'],
        user_id: params['userId'],
        invoice: params['invoice']
      };

      if (params['startDate']) {
        serverParams['start_date'] = params['startDate'];
      } else {
        serverParams['start_date'] = moment['default']().startOf('month').toDate();
      }

      if (params['endDate']) {

        serverParams['end_date'] = params['endDate'];
      }
      if (params['lockedStatus'] === 'unlocked' || !params['lockedStatus']) {
        serverParams['unlocked_only'] = 1;
      } else if (params['lockedStatus'] === 'locked') {
        serverParams['locked_only'] = 1;
      }

      return this.store.query('ticket', serverParams);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('hauler').then(function (haulers) {
        controller.set('haulers', haulers.sortBy('name'));
      });
      this.store.findAll('material').then(function (materials) {
        controller.set('materials', materials.sortBy('name'));
      });
      this.store.findAll('mill').then(function (mills) {
        controller.set('mills', mills.sortBy('name'));
      });
      this.store.query('job', { active: true }).then(function (jobs) {
        controller.set('jobs', jobs.sortBy('name'));
      });
      this.store.findAll('user').then(function (users) {
        controller.set('users', users.sortBy('name'));
      });
    }
  });

});