define('ironsides-ui/models/client', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var ClientModel;

  ClientModel = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    contact_person: DS['default'].attr('string'),
    address: DS['default'].attr('string'),
    phone: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    fax: DS['default'].attr('string'),
    po_number: DS['default'].attr('string'),
    notes: DS['default'].attr('string'),
    admin_notes: DS['default'].attr('string'),
    created_at: DS['default'].attr('date'),
    updated_at: DS['default'].attr('date')
  });

  exports['default'] = ClientModel;

});