define('ironsides-ui/views/modal', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ModalView;

  ModalView = Ember['default'].View.extend({
    layoutName: 'modal-layout',
    classNames: ['ui modal'],
    didInsertElement: function didInsertElement() {
      return this.$().modal({
        debug: true,
        selector: {
          close: '.close',
          approve: '',
          deny: ''
        },
        detachable: false,
        observeChanges: true,
        onHidden: (function (_this) {
          return function () {
            _this.set('isHidden', true);
            if (!_this.get('isRemoved')) {
              _this.set('isHidden', true);
              return _this._controller.send('cancel');
            }
          };
        })(this),
        onApprove: function onApprove() {
          return false;
        },
        onDeny: function onDeny() {
          return false;
        }
      }).modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.set('isRemoved', true);
      if (!this.get('isHidden')) {
        return this.$().modal('hide');
      }
    }
  });

  exports['default'] = ModalView;

});