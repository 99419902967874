define('ironsides-ui/tests/unit/models/timesheet-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel('timesheet', 'Timesheet', {
    needs: []
  });

  ember_qunit.test('it exists', function () {
    var model;
    model = this.subject();
    return ok(!!model);
  });

});